<template>
	<v-sheet class="create-Customer create-page" id="customer" style="margin: 0 -12px">
		<div class="container">
			<v-row class="sticky">
				<v-col md="6" class="my-auto">
					<h1 class="custom-header-blue-text m-0" v-if="this.project_renew > 0">
						Renew <span class="fw-600">{{ this.project.barcode }}</span>
					</h1>
					<h1 class="custom-header-blue-text m-0" v-else>{{ pageTitle }}</h1>
				</v-col>
				<v-col md="6" class="text-right">
					<v-btn
						:loading="pageLoading"
						class="white--text mr-2"
						depressed
						color="blue darken-4"
						tile
						:disabled="pageLoading || !formValid"
						@click="createOrUpdateProject"
					>
						Save
					</v-btn>
					<v-btn :disabled="pageLoading" depressed tile @click="cancleCreateProject"> Cancel </v-btn>
				</v-col>
			</v-row>

			<v-form
				ref="customer_submit"
				v-model.trim="formValid"
				lazy-validation
				@submit.stop.prevent="createOrUpdateProject"
			>
				<v-row>
					<v-col cols="9">
						<v-row class="mb-3 px-4">
							<v-col v-if="getValue(project, 'entity_type')" lg="4" cols="12" class="my-2 py-0">
								<label class="input-label fw-600">Entity</label>
							</v-col>
							<v-col v-if="getValue(project, 'entity_type')" lg="8" class="my-auto py-0">
								<div style="max-width: 11rem" class="fw-600 pa-1 pl-2 rounded-sm custom-radio-input">
									<img
										style="max-width: 25px"
										:src="
											getValue(project, 'entity_type') == 'bthrust'
												? $assetURL('media/project-logos/bthrust-icon.png')
												: getValue(project, 'entity_type') == 'genic-solution' &&
												  $assetURL('media/project-logos/genic.png')
										"
										alt="Entity"
									/>
									{{ mod_string(getValue(project, "entity_type"), "-", " ") }}
								</div>
							</v-col>
						</v-row>

						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label required">Customer</label>
							</v-col>
							<v-col lg="8" class="my-auto py-0">
								<v-autocomplete
									:items="customerData"
									hide-details
									outlined
									:readonly="(contract_id > 0 && projectId > 0) || project_renew > 0"
									placeholder="Customer"
									v-model="project.customer"
									item-text="company_name"
									item-value="id"
									v-on:change="getCustomerSalesPeson($event)"
									:rules="[vrules.required(project.customer, 'Customer')]"
									:class="{
										required: !project.customer,
									}"
								>
									<template v-slot:selection="data">
										<span class="text-uppercase">
											{{ data.item.company_name }} ({{ data.item.barcode }})
										</span>
									</template>
									<template v-slot:item="data">
										<template>
											<v-list-item-content style="min-width: 785px !important">
												<v-list-item-title
													>{{ data.item.company_name }}
													<div>
														<Chip
															:text="data.item.barcode"
															color="#24326d"
															outlined
															text-color=""
															v-if="data.item.barcode"
															class="mr-2"
														></Chip>
														<!-- 	<template v-if="data.item.contarct_draft_count">
																<v-badge  color="cyan" class="mr-4" :content="data.item.contarct_draft_count"> </v-badge>
															</template>
															<template v-else >
																<v-badge content="0" color="cyan"> </v-badge>
															</template> -->
														<template v-if="data.item.contarct_accepted_count">
															<v-badge color="green" class="mr-4" :content="data.item.contarct_accepted_count">
															</v-badge>
														</template>
														<template v-else>
															<v-badge content="0" color="green"> </v-badge>
														</template>
													</div>
												</v-list-item-title>
											</v-list-item-content>
										</template>
									</template>
								</v-autocomplete>
							</v-col>
						</v-row>

						<v-row class="mb-3 px-4" v-if="projectId == 0 && (!project_renew || project_renew == 0)">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label required">Contract</label>
							</v-col>
							<v-col lg="8" class="my-auto py-0">
								<v-autocomplete
									:items="customerContract"
									hide-details
									outlined
									placeholder="Contract"
									v-model="project.contract"
									item-text="title"
									item-value="id"
									v-on:change="getSingleContract(project.contract)"
									:rules="[vrules.required(project.contract, 'Contract')]"
									:class="{
										required: !project.contract,
									}"
								>
									<template v-slot:selection="data">
										<span class="text-uppercase"> {{ data.item.title }} ({{ data.item.barcode }}) </span>
									</template>
									<template v-slot:item="data">
										<template>
											<v-list-item-content style="min-width: 785px !important">
												<v-list-item-title
													>{{ data.item.title }}
													<div>
														<Chip
															:text="data.item.barcode"
															color="#24326d"
															outlined
															text-color=""
															v-if="data.item.barcode"
															class="mr-2"
														></Chip>
													</div>
												</v-list-item-title>
											</v-list-item-content>
										</template>
									</template>
								</v-autocomplete>
							</v-col>
						</v-row>

						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label required">Sales Person</label>
							</v-col>
							<v-col lg="8" class="my-auto py-0">
								<v-autocomplete
									:items="salesPserson"
									hide-details
									outlined
									placeholder="Sales Persons"
									v-model="project.user"
									item-text="display_name"
									item-value="id"
									:rules="[vrules.required(project.user, 'Sales Person')]"
									:class="{
										required: !project.user,
									}"
								>
									<template v-slot:selection="data">
										<v-chip small v-bind="data.attrs" :input-value="data.selected" @click="data.select">
											<v-avatar left>
												<img v-if="data.item.profile_img" :src="data.item.profile_img" />
												<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
											</v-avatar>
											{{ data.item.display_name }}
										</v-chip>
									</template>
									<template v-slot:item="data">
										<v-list-item-avatar>
											<img v-if="data.item.profile_img" :src="data.item.profile_img" />
											<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
										</v-list-item-avatar>
										<v-list-item-content>
											<v-list-item-title v-html="data.item.display_name"></v-list-item-title>
										</v-list-item-content>
									</template>
								</v-autocomplete>
							</v-col>
						</v-row>

						<v-row class="px-4" v-if="project.billing_type == 'single_billing' && projectId == 0">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label required" v-if="project.service_option == 'other'">
									Invoice Period
								</label>
								<label class="input-label required" v-else> Recurring Invoice Period </label>
							</v-col>
							<v-col lg="2" class="my-auto py-0">
								<label class="input-label required">Invoice Type</label>
							</v-col>
							<v-col lg="2" class="my-auto py-0">
								<label class="input-label required">Month(Period)</label>
							</v-col>
							<v-col lg="2" class="my-auto py-0">
								<label class="input-label required">Start Date</label>
							</v-col>
							<v-col lg="2" class="my-auto py-0">
								<label class="input-label required">End Date</label>
							</v-col>
						</v-row>

						<v-row class="mb-3 px-4" v-if="project.billing_type == 'single_billing' && projectId == 0">
							<v-col lg="4" cols="12" class="my-auto py-0"> </v-col>
							<v-col lg="2" cols="12" class="my-auto py-0 mt-3">
								<!-- @change="() => dateFormated(project, 'invoice'), changeMonthPeriod($event)" -->
								<v-autocomplete
									:items="contact_period_list"
									hide-details
									v-model="project.priod_month"
									outlined
									placeholder="Type"
									item-text="name"
									item-value="value"
									@change="changeMonthPeriod($event), dateFormated(project, 'invoice')"
									:rules="[vrules.required(project.priod_month, 'Type')]"
									:class="{
										required: !project.priod_month,
									}"
								></v-autocomplete>
							</v-col>
							<v-col lg="2" cols="12" class="my-auto py-0 mt-3">
								<!-- readonly-->
								<v-autocomplete
									:items="invoice_months"
									item-text="text"
									item-value="value"
									hide-details
									outlined
									placeholder="Month"
									v-model="project.invoice_duration"
									@change="() => dateFormated(project, 'invoice')"
									:rules="[vrules.required(project.invoice_duration, 'Invoice Duration')]"
									:class="{
										required: !project.invoice_duration,
									}"
								>
								</v-autocomplete>
							</v-col>

							<v-col lg="2" class="my-auto py-0">
								<DatePicker
									hide-details
									clearable
									:disabled="pageLoading"
									:loading="pageLoading"
									id="project-date"
									placeholder="Start Date"
									v-model="project.invoice_start_date"
									@change="() => dateFormated(project, 'invoice')"
									:disablePastFrom="disablePastEndDate"
									:minDate="project_renew && +project_renew > 0 ? restrictStartDateWhenRenew : undefined"
									:rules="[vrules.required(project.invoice_start_date, 'Start Date')]"
									:class="{
										required: !project.invoice_start_date,
									}"
								>
								</DatePicker>
							</v-col>

							<v-col lg="2" class="my-auto py-0">
								<DatePicker
									hide-details
									readonly
									:disabled="pageLoading"
									:loading="pageLoading"
									id="project-date"
									placeholder="End Date"
									v-model="project.invoice_end_date"
									:minDate="project.invoice_start_date"
									:rules="[vrules.required(project.invoice_end_date, 'End Date')]"
									:class="{
										required: !project.invoice_end_date,
									}"
								>
								</DatePicker>
							</v-col>
						</v-row>

						<v-row class="mb-3 px-4" v-if="project.billing_type == 'single_billing' && projectId == 0">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label">Total Invoice</label>
							</v-col>
							<v-col lg="8" class="my-auto py-0">
								<span v-if="project.priod_month" class="primary--text"
									><b>{{ project.total_invoice }}</b></span
								>
							</v-col>
						</v-row>

						<v-row class="mb-3 px-4" v-if="project.billing_type == 'single_billing' && projectId == 0">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label required">Total Cost</label>
							</v-col>
							<v-col lg="8" class="my-auto py-0">
								<!-- prepend-inner-icon="mdi-currency-usd" -->
								<v-text-field
									hide-details
									outlined
									placeholder="Cost"
									type="number"
									min="0"
									max="9999"
									prefix="$"
									:rules="[vrules.required(project.cost, 'cost')]"
									:class="{
										required: !project.cost,
									}"
									v-model.trim.number.lazy="project.cost"
									@keyup="maintainDates(project.billing_type)"
									@paste="disablePaste"
									@keypress="limitDecimal($event, project.cost)"
								></v-text-field>
							</v-col>
						</v-row>

						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label required">Service Option</label>
							</v-col>
							<v-col cols="12" lg="8">
								<RadioInputCustom
									v-model="project.service_option"
									row
									:mandatory="false"
									color="blue darken-4"
									:items="[
										{
											label: 'Software',
											value: 'software',
										},
										{
											label: 'Other',
											value: 'other',
										},
									]"
									@change="filterServiceType($event)"
									:rules="[vrules.required(project.service_option, 'Service Option')]"
									:class="{
										required: !project.service_option,
									}"
								/>
							</v-col>
						</v-row>

						<v-row class="mb-3 px-4" v-if="project.service_option == 'other'">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label required">Service Type</label>
							</v-col>
							<v-col lg="8" class="my-auto py-0 d-flex" v-if="project.service_option == 'other'">
								<v-flex class="mr-5">
									<v-checkbox
										v-model="project.checkbox_type"
										label="SEO"
										value="seo"
										hide-details
										v-on:change="hostingFuncyion('seo')"
										class="ma-0"
									></v-checkbox>
								</v-flex>
								<v-flex class="mr-5">
									<v-checkbox
										v-model="project.checkbox_type"
										label="Hosting"
										value="hosting"
										v-on:change="hostingFuncyion('hosting')"
										hide-details
										class="ma-0"
									></v-checkbox>
								</v-flex>
								<v-flex class="mr-5">
									<v-checkbox
										v-model="project.checkbox_type"
										label="SSL"
										value="ssl"
										hide-details
										v-on:change="hostingFuncyion('ssl')"
										class="ma-0"
									></v-checkbox>
								</v-flex>
								<v-flex class="mr-5">
									<v-checkbox
										v-model="project.checkbox_type"
										label="Development of Website "
										value="web"
										hide-details
										v-on:change="hostingFuncyion('web')"
										class="ma-0"
									></v-checkbox>
								</v-flex>
								<v-flex class="mr-5">
									<v-checkbox
										v-model="project.checkbox_type"
										label="Domain"
										value="domain"
										hide-details
										v-on:change="hostingFuncyion('domain')"
										class="ma-0"
									></v-checkbox>
								</v-flex>
								<v-flex class="mr-5">
									<v-checkbox
										v-model="project.checkbox_type"
										label="Maintenance of website"
										value="maintenance"
										hide-details
										v-on:change="hostingFuncyion('maintenance')"
										class="ma-0"
									></v-checkbox>
								</v-flex>
							</v-col>
							<v-col
								lg="8"
								class="my-auto py-0 d-flex"
								v-if="false && project.service_option == 'software'"
							>
								<v-flex class="mr-5">
									<v-checkbox
										v-model="project.checkbox_type"
										label="Software"
										value="software"
										hide-details
										v-on:change="hostingFuncyion('software')"
										class="ma-0"
									></v-checkbox>
								</v-flex>
							</v-col>
						</v-row>

						<v-tabs
							background-color="transparent"
							color="blue"
							class="custom-tab-transparent border-bottom w-100"
							active-class="blue darken-4 text-white"
							hide-slider
							v-if="!project.checkbox_type.length"
						>
						</v-tabs>

						<v-row
							style="position: sticky; top: 35px"
							class="px-4 mt-2 grey lighten-4"
							v-if="project.checkbox_type.length"
						>
							<v-col>
								<template>
									<v-tabs
										v-model="dataTab"
										background-color="transparent"
										color="blue"
										class="custom-tab-transparent border-bottom w-100"
										active-class="blue darken-4 text-white"
										hide-slider
									>
										<v-tab
											href="#seo"
											v-if="project.checkbox_type.length && project.checkbox_type.includes('seo')"
										>
											<v-icon small left>mdi-information-outline</v-icon>SEO
										</v-tab>
										<v-tab
											href="#hosting"
											v-if="project.checkbox_type.length && project.checkbox_type.includes('hosting')"
											><v-icon small left>mdi-information-outline</v-icon>Hosting
										</v-tab>
										<v-tab
											href="#ssl"
											v-if="project.checkbox_type.length && project.checkbox_type.includes('ssl')"
											><v-icon small left>mdi-information-outline</v-icon>SSL
										</v-tab>
										<v-tab
											href="#web"
											v-if="project.checkbox_type.length && project.checkbox_type.includes('web')"
											><v-icon small left>mdi-note</v-icon>Web</v-tab
										>
										<v-tab
											href="#domain"
											v-if="project.checkbox_type.length && project.checkbox_type.includes('domain')"
											><v-icon small left>mdi-information-outline</v-icon>Domain</v-tab
										>
										<v-tab
											href="#maintenance"
											v-if="project.checkbox_type.length && project.checkbox_type.includes('maintenance')"
											><v-icon small left>mdi-note</v-icon>Maintenance
										</v-tab>

										<v-tab
											href="#software"
											v-if="project.checkbox_type.length && project.checkbox_type.includes('software')"
											><v-icon small left>mdi-desktop-classic</v-icon>Software
										</v-tab>
									</v-tabs>
								</template>
							</v-col>
						</v-row>
						<v-tabs-items style="max-height: calc(100vh - 170px); overflow-y: auto" v-model="dataTab">
							<v-tab-item value="seo" class="pt-2">
								<template v-if="project.checkbox_type.length && project.checkbox_type.includes('seo')">
									<!-- <v-row class="px-4">
												<v-col lg="4" cols="12" class="my-auto px-0" style="font-size: 18px; color: black; font-weight: 600;"> THIS SERVICE IS DONE BY BTHRUST UNDER THE PACKAGE PRICE  OF SGD  <span style="color:red">{{formatMoney(project.cost)}}</span>  ONLY AND NOT CHARGED SEPARATELY</v-col>
												<v-col lg="8" class="my-auto py-0">
										
												</v-col>
											</v-row> -->
									<div class="form-grp-title d-flex">
										<v-row class="px-4">
											<v-col lg="4" cols="12" class="my-auto px-0">SEO</v-col>
											<v-col lg="8" class="my-auto py-0">
												<v-radio-group
													v-model="project.seo_type"
													row
													hide-details
													v-on:change="resetSeoList(project.seo_type)"
													class="mt-0"
												>
													<v-radio label="Single" value="single"></v-radio>
													<v-radio label="Combined" value="combined"></v-radio>
												</v-radio-group>
											</v-col>
										</v-row>
									</div>
									<div>
										<template v-for="(data, index) in project.customer_services">
											<div :key="`index${index}`" class="parent-element">
												<v-row class="mb-3 px-4">
													<v-col lg="4" cols="12" class="my-auto py-0">
														<label class="input-label">Keyword</label>
													</v-col>
													<v-col lg="8" class="my-auto py-0">
														<v-row>
															<v-col cols="4" md="3" class="py-0">
																<v-text-field
																	v-model="project.customer_services[index].keyword_monthly"
																	outlined
																	hide-details
																	:min="0"
																	placeholder="Keyword"
																	@keypress="allowInput($event, ['number'])"
																	@paste="disablePaste"
																	v-on:change="() => dateFormated(project.customer_services[index], index)"
																>
																</v-text-field>
															</v-col>
															<div class="my-auto">+</div>
															<v-col cols="4" md="3" class="py-0">
																<v-text-field
																	v-model="project.customer_services[index].keyword_free"
																	outlined
																	hide-details
																	:min="0"
																	placeholder="Free"
																	@keypress="allowInput($event, ['number'])"
																	@paste="disablePaste"
																	v-on:keyup="() => dateFormated(project.customer_services[index], index)"
																>
																</v-text-field>
															</v-col>
														</v-row>
													</v-col>
												</v-row>
												<v-row class="mb-3 px-4">
													<v-col lg="4" cols="12" class="my-auto py-0">
														<label class="input-label required">Seo Duration</label>
													</v-col>
													<v-col lg="8" class="my-auto py-0">
														<v-row>
															<v-col cols="4" md="3" class="py-0">
																<v-autocomplete
																	:items="months"
																	item-text="text"
																	item-value="value"
																	hide-details
																	outlined
																	placeholder="Month"
																	:rules="[vrules.required(project.customer_services[index].total_time, 'Duration')]"
																	v-model="project.customer_services[index].total_time"
																	@keypress="allowInput($event, ['number'])"
																	@paste="disablePaste"
																	v-on:change="
																		() => dateFormated(project.customer_services[index], index, 'seo-service')
																	"
																>
																</v-autocomplete>
															</v-col>
															<div class="my-auto">+</div>
															<v-col cols="4" md="3" class="py-0">
																<v-text-field
																	v-model="project.customer_services[index].time_free"
																	outlined
																	hide-details
																	placeholder="Free"
																	@keypress="allowInput($event, ['number'])"
																	@paste="disablePaste"
																	@keyup="() => dateFormated(project.customer_services[index], index, 'seo-service')"
																>
																</v-text-field>
															</v-col>
														</v-row>
													</v-col>
												</v-row>
												<v-row class="px-4">
													<v-col lg="4" cols="12" class="my-auto py-0">
														<label class="input-label">Seo Period</label>
													</v-col>
													<v-col lg="8" cols="12">
														<v-row>
															<v-col lg="4" class="my-auto py-0"
																><label class="input-label">Start Date</label></v-col
															>
															<v-col lg="4" class="my-auto py-0"
																><label class="input-label">End Date</label></v-col
															>
															<v-col lg="4" class="my-auto py-0"
																><label class="input-label">Extend Free Date</label></v-col
															>
														</v-row>
													</v-col>
												</v-row>
												<v-row class="mb-3 px-4">
													<v-col lg="4" cols="12" class="my-auto py-0"> </v-col>
													<v-col lg="8" class="my-auto py-0">
														<v-row>
															<v-col lg="4" class="my-auto py-0">
																<DatePicker
																	hide-details
																	hideTopMargin
																	clearable
																	:disabled="pageLoading"
																	:loading="pageLoading"
																	id="contract-date"
																	placeholder="Start Date"
																	v-model="project.customer_services[index].select_date"
																	@click:clear="
																		() => {
																			project.customer_services[index].select_date = null;
																			dateFormated(project.customer_services[index], index, 'seo-service');
																		}
																	"
																	@change="
																		() => dateFormated(project.customer_services[index], index, 'seo-service')
																	"
																>
																</DatePicker>
															</v-col>
															<v-col lg="4" class="my-auto py-0">
																<DatePicker
																	hideTopMargin
																	hide-details
																	clearable
																	:disabled="pageLoading"
																	:loading="pageLoading"
																	id="contract-date"
																	placeholder="End Date"
																	readonly
																	v-model="project.customer_services[index].total_time_end"
																	:min-date="
																		getValue(disableDates, 'seo') && getValue(disableDates, 'seo').length
																			? getValue(disableDates, `seo.${index}`)
																			: undefined
																	"
																	@change="
																		() => dateFormated(project.customer_services[index], index, 'seo-service')
																	"
																>
																</DatePicker>
															</v-col>
															<v-col lg="4" class="my-auto py-0">
																<DatePicker
																	hide-details
																	hideTopMargin
																	clearable
																	:disabled="pageLoading"
																	:loading="pageLoading"
																	id="contract-date"
																	readonly
																	placeholder="Extend Date"
																	v-model="project.customer_services[index].time_free_end"
																	:min-date="
																		getValue(disableDates, 'seo_free') && getValue(disableDates, 'seo_free').length
																			? getValue(disableDates, `seo_free.${index}`)
																			: undefined
																	"
																	v-on:change="
																		() => dateFormated(project.customer_services[index], index, 'seo-service')
																	"
																>
																</DatePicker>
															</v-col>
														</v-row>
													</v-col>
												</v-row>

												<v-row class="mb-3 px-4">
													<v-col lg="4" cols="12" class="my-auto py-0">
														<label class="input-label">Project Manager</label>
													</v-col>

													<v-col lg="8" class="my-auto py-0">
														<v-autocomplete
															:items="users"
															item-text="display_name"
															item-value="id"
															hide-details
															outlined
															placeholder="Project Manager"
															v-model="project.customer_services[index].project_manager"
														>
														</v-autocomplete>
													</v-col>
												</v-row>

												<v-row class="mb-3 px-4">
													<v-col lg="4" cols="12" class="my-auto py-0">
														<label class="input-label">Remark </label>
													</v-col>

													<v-col lg="8" class="my-auto py-0">
														<TextAreaInput
															:rows="4"
															dense
															ref="comment"
															:word-limit="200"
															hide-details
															v-model="project.customer_services[index].remark"
															placeholder="Enter Remark"
															:disabled="pageLoading"
															:loading="pageLoading"
														></TextAreaInput>
													</v-col>
												</v-row>

												<div
													v-for="(child_data, child_index) in data.customer_urls"
													:key="`child_index${child_index}`"
												>
													<!-- <v-row class="mb-3 px-4">
																<v-col lg="4" cols="12" class="my-auto py-0">
																	<label class="input-label">Upload Keyword</label>
																</v-col>
																<v-col lg="8" class="my-auto py-0">
																	<v-row>
																							<v-col cols="4" md="3" class="py-0">
																			<v-text-field
																				v-model="project.customer_services[index].customer_urls[child_index].keyword_monthly"
																				:rules="[vrules.required(project.customer_services[index].customer_urls[child_index].keyword_monthly, 'Keyword')]"
																				:class="{
																					required: !project.customer_services[index].customer_urls[child_index].keyword_monthly,
																				}"
																				outlined hide-details placeholder="Keyword">
																			</v-text-field>
																		</v-col>
																		<div class="my-auto">+</div>
																		<v-col cols="4" md="3" class="py-0">
																			<v-text-field
																				v-model="project.customer_services[index].customer_urls[child_index].keyword_free"
																				outlined hide-details placeholder="Free">
																			</v-text-field>
																		</v-col>
																	</v-row>
																</v-col>
															</v-row> -->
													<v-row class="mb-3 px-4">
														<v-col lg="4" cols="12" class="my-auto py-0">
															<label class="input-label required">URL</label>
														</v-col>
														<v-col lg="8" class="my-auto py-0">
															<v-text-field
																hide-details
																outlined
																placeholder="URL"
																v-model="project.customer_services[index].customer_urls[child_index].website_urls"
																@input="urlInput($event)"
																:rules="[
																	vrules.validUrl(
																		project.customer_services[index].customer_urls[child_index].website_urls,
																		'URL'
																	) /* urlRules */,
																]"
																@blur="assignToDomain"
																:class="{
																	required:
																		!project.customer_services[index].customer_urls[child_index].website_urls,
																}"
															>
															</v-text-field>
														</v-col>
													</v-row>

													<v-row class="px-4 mb-3">
														<v-col lg="4" cols="12" class="my-auto py-0">
															<label class="input-label required">Target Google</label>
														</v-col>
														<v-col lg="8" class="my-auto py-0 position-relative">
															<v-autocomplete
																:items="target_list"
																item-text="text"
																item-value="id"
																hide-details
																outlined
																placeholder="Target Google"
																v-model="
																	project.customer_services[index].customer_urls[child_index].url_target_google
																"
																:rules="[
																	vrules.required(
																		project.customer_services[index].customer_urls[child_index].url_target_google,
																		'Target Google'
																	),
																]"
																:class="{
																	required:
																		!project.customer_services[index].customer_urls[child_index].url_target_google,
																}"
															>
																<template v-slot:selection="data">
																	<!-- <v-avatar left>
																				<inline-svg :src="$assetURL('media/svg/flags/230-singapore.svg')" />
																			</v-avatar> -->
																	<div class="d-flex">
																		<div class="svg-custom-width mr-2">
																			<inline-svg :src="$assetURL(`media/svg/flags/${data.item.status_color}.svg`)" />
																		</div>
																		<div>
																			{{ data.item.text }}
																		</div>
																	</div>
																</template>
																<template v-slot:item="data">
																	<template>
																		<v-list-item-content>
																			<v-list-item-title>
																				<div class="svg-custom-width my-1">
																					<inline-svg
																						:src="$assetURL(`media/svg/flags/${data.item.status_color}.svg`)"
																					/>
																					{{ data.item.text }}
																				</div>
																			</v-list-item-title>
																		</v-list-item-content>
																	</template>
																</template>
															</v-autocomplete>
														</v-col>
														<v-col v-if="index == 0 && child_index == 0" md="12">
															<v-divider></v-divider>
														</v-col>

														<template
															v-if="
																otherTargetCountryActive(
																	project.customer_services[index].customer_urls[child_index].url_target_google
																)
															"
														>
															<v-col lg="4" cols="12" class="my-auto py-0">
																<label class="input-label"></label>
															</v-col>
															<v-col lg="8" class="my-auto py-0 position-relative">
																<TextInput
																	:id="`target-google`"
																	v-model="
																		project.customer_services[index].customer_urls[child_index].other_target_google
																	"
																	hide-details
																	placeholder="Other Target Google"
																></TextInput>
															</v-col>
														</template>
													</v-row>

													<template v-if="false">
														<!-- project.seo_type == 'combined' -->
														<template
															v-for="(kpi_file, index) in project.customer_services[index].customer_urls[
																child_index
															].keyword_files"
														>
															<v-row class="mb-3 px-4" :key="index">
																<v-col lg="4" cols="12" class="my-auto py-0">
																	<label class="input-label">keyword file</label>
																</v-col>
																<v-col lg="4" cols="4" class="my-auto py-0 d-flex align-items-center">
																	<v-file-input
																		:id="`document-file-${index}`"
																		placeholder="Select File"
																		outlined
																		class="mt-3"
																		prepend-icon=""
																		prepend-inner-icon="mdi-attachment"
																		hide-details
																		v-model="kpi_file.file"
																		v-on:change="
																			updateFile(
																				index,
																				$event,
																				'keyword',
																				project.customer_services[index].customer_urls[child_index].keyword_files
																			)
																		"
																		v-on:click:clear="
																			updateFile(
																				index,
																				$event,
																				'keyword',
																				project.customer_services[index].customer_urls[child_index].keyword_files
																			)
																		"
																	></v-file-input>
																</v-col>
																<v-col lg="4" cols="4" class="my-auto py-0 d-flex align-items-center">
																	<TextInput
																		:id="`document-name-${index}`"
																		v-model="kpi_file.name"
																		hide-details
																		placeholder="File Name"
																		:suffix="kpi_file.suffix"
																	></TextInput>
																</v-col>
															</v-row>
														</template>
													</template>

													<v-row
														class="px-4"
														v-if="
															getValue(project, 'customer_services.0.customer_urls') &&
															getValue(project, 'customer_services.0.customer_urls').length > 1 &&
															child_index > 0
														"
													>
														<v-col lg="4" cols="12" class="mt-3 py-0">
															<v-btn
																tile
																depressed
																color="red white--text"
																v-on:click="
																	removeSeoField(
																		child_index,
																		project.customer_services[0].customer_urls[child_index].id
																	)
																"
															>
																<v-icon>mdi-delete</v-icon>
																Remove
															</v-btn>
														</v-col>
														<v-col md="12">
															<v-divider></v-divider>
														</v-col>
													</v-row>

													<v-col
														cols="12"
														class="pt-0"
														v-if="
															getValue(project, 'customer_services.0.customer_urls') &&
															getValue(project, 'customer_services.0.customer_urls').length > 1 > 1
														"
													>
														<v-divider></v-divider>
													</v-col>
												</div>

												<v-row
													class="mb-3 px-4"
													v-if="
														project &&
														project.customer_services &&
														project.customer_services.length > 1 &&
														index > 0
													"
												>
													<v-col lg="4" cols="12" class="my-auto py-0">
														<template v-if="projectId > 0">
															<v-btn
																tile
																depressed
																color="red white--text"
																v-on:click="deleteServices(project.customer_services[index].id, index)"
															>
																<v-icon>mdi-delete</v-icon>
																Remove
															</v-btn>
														</template>
														<template v-else>
															<v-btn tile depressed color="red white--text" v-on:click="removeSeoField(index)">
																<v-icon>mdi-delete</v-icon>
																Remove
															</v-btn>
														</template>
													</v-col>
												</v-row>

												<v-col
													cols="12"
													class="pt-0"
													v-if="
														project &&
														project.customer_services &&
														project.customer_services.length > 1 &&
														index > 0
													"
												>
													<v-divider></v-divider>
												</v-col>
											</div>
										</template>
										<!-- ADD MORE BUTTON -->
										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<v-btn tile depressed color="blue darken-4 white--text" v-on:click="addSeoField()">
													<v-icon>mdi-plus</v-icon>
													Add more
												</v-btn>
											</v-col>
										</v-row>
									</div>
								</template>
							</v-tab-item>

							<v-tab-item value="hosting">
								<template v-if="project.checkbox_type.length && project.checkbox_type.includes('hosting')">
									<template v-if="getValue(project, 'hosting.0.type') == 'bthrust'">
										<v-row class="px-4">
											<v-col
												lg="12"
												cols="12"
												class="my-auto px-0"
												style="font-size: 18px; color: black; font-weight: 600"
											>
												THIS SERVICE IS DONE BY BTHRUST UNDER THE PACKAGE PRICE OF SGD
												<span style="color: red">{{ formatMoney(project.cost) }}</span> ONLY AND NOT CHARGED
												SEPARATELY</v-col
											>
										</v-row>
										<!-- <div class="form-grp-title d-flex"> -->
										<!-- <v-row class="px-4">
													<v-col lg="4" cols="12" class="my-auto px-0"> Entity</v-col>

													<v-col lg="8" class="my-auto py-0">
														<v-radio-group
															v-model="project.hosting[0].company_type"
															row
															readonly
															hide-details
															class="mt-0"
														>
															<v-radio value="bthrust"></v-radio>
															<label for="" class="ml-n4 mr-4"
																><img
																	style="max-width: 35px; height: 22px"
																	:src="$assetURL('media/project-logos/bthrust-icon.png')"
																	alt=""
																/>
																Bthrust</label
															>
															<v-radio value="genic-solution"></v-radio>
															<label for="" class="ml-n4 mr-4"
																><img
																	style="max-width: 35px; height: 22px"
																	:src="$assetURL('media/project-logos/genic.png')"
																	alt=""
																/>
																Genic Solution</label
															>
														</v-radio-group>
													</v-col>
												</v-row> -->
										<!-- </div> -->
										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label">Service Provider</label>
											</v-col>
											<v-col lg="8" class="my-auto py-0 position-relative">
												<v-autocomplete
													:items="getServiceProvider('is_hosting')"
													item-text="text"
													item-value="value"
													hide-details
													outlined
													clearable
													placeholder="Service Provider"
													v-model="project.hosting[0].service_provider"
													@change="filterServers($event)"
												>
												</v-autocomplete>
												<v-tooltip right v-if="false">
													<template #activator="{ on, attrs }">
														<v-icon
															link
															v-on="on"
															v-bind="attrs"
															class="input-addon"
															:color="'primary'"
															v-on:click="serviceProviderDrawer = true"
														>
															mdi-cog
														</v-icon>
													</template>
													<span>Service Provider Settings</span>
												</v-tooltip>
												<CreateServiceProvider
													:title="'Service Provider'"
													:type-id="50"
													v-if="serviceProviderDrawer"
													:drawer="serviceProviderDrawer"
													@close="serviceProviderDrawer = false"
													v-on:success="
														serviceProviderDrawer = false;
														getSettings();
														deleteSPType = 'hosting';
													"
												></CreateServiceProvider>
											</v-col>
										</v-row>
										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label">Server</label>
											</v-col>

											<v-col lg="8" class="my-auto py-0 position-relative">
												<v-autocomplete
													:id="serverid"
													:items="serverWeb"
													item-text="name"
													item-value="id"
													hide-details
													outlined
													placeholder="Server"
													v-model="project.hosting[0].server"
												>
												</v-autocomplete>
											</v-col>
										</v-row>
										<v-row class="px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label">Hosting Period</label>
											</v-col>
											<v-col lg="2" class="my-auto py-0"
												><label class="input-label required">Duration</label></v-col
											>
											<v-col lg="3" class="my-auto py-0"><label class="input-label">Start Date</label></v-col>
											<v-col lg="3" class="my-auto py-0"><label class="input-label">End Date</label></v-col>
										</v-row>
										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0"> </v-col>
											<v-col lg="2" class="my-auto py-0">
												<v-autocomplete
													:items="months"
													item-text="text"
													item-value="value"
													hide-details
													outlined
													placeholder="Month"
													@keypress="allowInput($event, ['number'])"
													@paste="disablePaste"
													:rules="[vrules.required(project.hosting[0].duration, 'Duration')]"
													:class="{
														required: !project.hosting[0].duration,
													}"
													v-model="project.hosting[0].duration"
													v-on:change="() => dateFormated(project.hosting[0], 'hosting')"
												>
												</v-autocomplete>
											</v-col>
											<v-col lg="3" class="my-auto py-0">
												<DatePicker
													hide-details
													hideTopMargin
													clearable
													:disabled="pageLoading"
													:loading="pageLoading"
													id="start-date"
													placeholder="Start Date"
													v-model="project.hosting[0].start_date"
													v-on:change="() => dateFormated(project.hosting[0], 'hosting')"
												>
												</DatePicker>
											</v-col>
											<v-col lg="3" class="my-auto py-0">
												<DatePicker
													hide-details
													hideTopMargin
													clearable
													readonly
													:disabled="pageLoading"
													:loading="pageLoading"
													id="start-date"
													placeholder="End Date"
													v-model="project.hosting[0].end_date"
												></DatePicker>
											</v-col>
										</v-row>
										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label">Web Url</label>
											</v-col>
											<v-col lg="8" class="my-auto py-0">
												<v-text-field
													outlined
													hide-details
													placeholder="Web Url"
													v-model="project.hosting[0].domain"
													:rules="[vrules.validUrl(project.hosting[0].domain, 'Domain', true)]"
												/>
											</v-col>
										</v-row>
										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label">Remark </label>
											</v-col>

											<v-col lg="8" class="my-auto py-0">
												<TextAreaInput
													:rows="4"
													dense
													ref="comment"
													:word-limit="200"
													hide-details
													v-model="project.hosting[0].remark"
													placeholder="Enter Remark"
													:disabled="pageLoading"
													:loading="pageLoading"
												></TextAreaInput>
											</v-col>
										</v-row>
									</template>
								</template>
							</v-tab-item>

							<v-tab-item value="ssl">
								<template v-if="project.checkbox_type.length && project.checkbox_type.includes('ssl')">
									<template v-if="getValue(project, 'ssl.0.type') == 'bthrust'">
										<v-row class="px-4">
											<v-col
												lg="12"
												cols="12"
												class="my-auto px-0"
												style="font-size: 18px; color: black; font-weight: 600"
											>
												THIS SERVICE IS DONE BY BTHRUST UNDER THE PACKAGE PRICE OF SGD
												<span style="color: red">{{ formatMoney(project.cost) }}</span> ONLY AND NOT CHARGED
												SEPARATELY</v-col
											>
										</v-row>
										<div v-for="(ssl, idx) in project.ssl" :key="'ssl_tab_' + idx">
											<!-- <div class="form-grp-title d-flex">
													<v-row class="px-4">
														<v-col lg="4" cols="12" class="my-auto px-0"> Entity </v-col>

														<v-col lg="8" class="my-auto py-0">
															<v-radio-group v-model="ssl.company_type" readonly row hide-details class="mt-0">
																<v-radio value="bthrust"></v-radio>
																<label for="" class="ml-n4 mr-4"
																	><img
																		style="max-width: 35px; height: 22px"
																		:src="$assetURL('media/project-logos/bthrust-icon.png')"
																		alt=""
																	/>
																	Bthrust</label
																>
																<v-radio value="genic-solution"></v-radio>
																<label for="" class="ml-n4 mr-4"
																	><img
																		style="max-width: 35px; height: 22px"
																		:src="$assetURL('media/project-logos/genic.png')"
																		alt=""
																	/>
																	Genic Solution</label
																>
															</v-radio-group>
														</v-col>
													</v-row>
												</div> -->
											<v-row class="mb-3 px-4">
												<v-col lg="4" cols="12" class="my-auto py-0">
													<label class="input-label">Service Provider</label>
												</v-col>
												<v-col lg="8" class="my-auto py-0 position-relative">
													<v-autocomplete
														:items="getServiceProvider('is_ssl')"
														item-text="text"
														item-value="value"
														hide-details
														outlined
														placeholder="Service Provider"
														v-model="project.ssl[idx].service_provider"
													>
													</v-autocomplete>
													<!-- <CreateServer
														:title="'Service Provider'"
														:type-id="50"
														v-on:success="getSettings()"
													></CreateServer> -->

													<v-tooltip right v-if="false">
														<template #activator="{ on, attrs }">
															<v-icon
																link
																v-on="on"
																v-bind="attrs"
																class="input-addon"
																:color="'primary'"
																v-on:click="serviceProviderDrawer = true"
															>
																mdi-cog
															</v-icon>
														</template>
														<span>Service Provider Settings</span>
													</v-tooltip>
													<CreateServiceProvider
														:title="'Service Provider'"
														:type-id="50"
														v-if="serviceProviderDrawer"
														:drawer="serviceProviderDrawer"
														@close="serviceProviderDrawer = false"
														v-on:success="
															serviceProviderDrawer = false;
															getSettings();
															deleteSPType = `ssl:${idx}`;
														"
													></CreateServiceProvider>
												</v-col>
											</v-row>
											<v-row class="mb-3 px-4">
												<v-col lg="4" cols="12" class="my-auto py-0">
													<label class="input-label">Web Url</label>
												</v-col>
												<v-col lg="8" class="my-auto py-0">
													<!-- <v-text-field
														outlined
														hide-details
														placeholder="Web Url"
														v-model="project.ssl[idx].domain_name"
														:readonly="domainFieldDisabled(idx)"
														@focus="onfocus"
														:rules="urlRules"
														:class="{
															required: !ssl.domain_name,
														}"
													>
													</v-text-field> -->
													<v-text-field
														outlined
														hide-details
														placeholder="Web Url"
														v-model="project.ssl[idx].domain_name"
														@focus="onfocus"
														:rules="urlRules"
														:class="{
															required: !ssl.domain_name,
														}"
													>
													</v-text-field>
												</v-col>
											</v-row>
											<v-row class="px-4">
												<v-col lg="4" cols="12" class="my-auto py-0">
													<label class="input-label">SSL Period</label>
												</v-col>
												<v-col lg="2" class="my-auto py-0"
													><label class="input-label required">Duration</label></v-col
												>
												<v-col lg="3" class="my-auto py-0"
													><label class="input-label">Start Date</label>
												</v-col>
												<v-col lg="3" class="my-auto py-0"><label class="input-label">End Date</label></v-col>
											</v-row>
											<v-row class="mb-3 px-4">
												<v-col lg="4" cols="12" class="my-auto py-0"> </v-col>
												<v-col lg="2" class="my-auto py-0">
													<v-autocomplete
														:items="months"
														item-text="text"
														item-value="value"
														hide-details
														outlined
														placeholder="Month"
														v-model="project.ssl[idx].duration"
														@keypress="allowInput($event, ['number'])"
														@paste="disablePaste"
														:rules="[vrules.required(ssl.duration, 'Duration')]"
														:class="{
															required: !ssl.duration,
														}"
														v-on:change="() => dateFormated(ssl, idx, 'ssl')"
													>
													</v-autocomplete>
												</v-col>
												<v-col lg="3" class="my-auto py-0">
													<DatePicker
														hide-details
														hideTopMargin
														clearable
														:disabled="pageLoading"
														:loading="pageLoading"
														id="start-date"
														placeholder="Start Date"
														v-model="project.ssl[idx].start_date"
														v-on:change="() => dateFormated(ssl, idx, 'ssl')"
													>
													</DatePicker>
												</v-col>
												<v-col lg="3" class="my-auto py-0">
													<DatePicker
														hide-details
														hideTopMargin
														readonly
														clearable
														:disabled="pageLoading"
														:loading="pageLoading"
														id="start-date"
														placeholder="End Date"
														v-model="project.ssl[idx].end_date"
														:min-date="
															getValue(disableDates, 'ssl') ? getValue(disableDates, `ssl.${idx}`) : undefined
														"
													></DatePicker>
												</v-col>
											</v-row>
											<v-row class="mb-1 px-4">
												<v-col lg="4" cols="12" class="my-auto py-0">
													<label class="input-label">Remark </label>
												</v-col>
												<v-col lg="8" class="my-auto py-0">
													<TextAreaInput
														:rows="4"
														dense
														ref="comment"
														hide-details
														:word-limit="200"
														v-model="project.ssl[idx].remark"
														placeholder="Enter Remark"
														:disabled="pageLoading"
														:loading="pageLoading"
													></TextAreaInput>
												</v-col>
											</v-row>

											<v-row class="mb-3 px-4">
												<v-col lg="4" cols="12" class="my-auto py-0">
													<label class="input-label">Notification Person</label>
												</v-col>

												<v-col lg="8" class="my-auto py-0">
													<AutoCompleteInput
														hide-details
														multiple
														hide-top-margin
														v-model="project.ssl[idx].notification_person"
														:items.sync="users"
														:disabled="pageLoading"
														:loading="pageLoading"
														custom-class="mt-0"
														item-text="display_name"
														item-value="id"
														placeholder="Notification Person"
													>
														<template #noStyleItem="{ li, attrs }">
															<div class="w-100 d-flex align-center justify-space-between py-1">
																<div class="d-flex align-center">
																	<v-avatar size="36" class="mr-2">
																		<v-img v-if="li.profile_img" :src="li.profile_img" />
																		<v-img v-else src="/media/users/blank.png" />
																	</v-avatar>
																	<div>
																		<div class="fw-600 text-uppercase">
																			{{ li.display_name }}
																		</div>
																		<div class="text-muted fw-500">
																			{{ li.email }}
																		</div>
																	</div>
																</div>
																<v-icon color="green" v-if="attrs['aria-selected'] == 'true'"
																	>mdi-check-circle-outline</v-icon
																>
															</div>
														</template>
														<template #selectedItem="{ item }">
															<v-chip class="px-2" color="blue lighten-5">
																<v-avatar class="mr-2" v-if="item.profile_img">
																	<v-img :src="item.profile_img" />
																</v-avatar>
																<div class="fw-600 text-uppercase">
																	{{ item.display_name }}
																</div>
															</v-chip>
														</template>
													</AutoCompleteInput>
												</v-col>
											</v-row>

											<v-row class="mb-3 px-4" v-if="idx > 0">
												<v-col md="12">
													<v-btn
														tile
														depressed
														color="red white--text"
														:disabled="domainFieldDisabled(idx)"
														v-on:click="removeSslField(idx)"
													>
														<v-icon>mdi-delete</v-icon>
														Remove
													</v-btn>
												</v-col>
											</v-row>

											<v-divider v-if="project.ssl.length > 1 && idx + 1 != project.ssl.length"></v-divider>
										</div>
										<!-- ADD MORE BUTTON -->
										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<v-btn tile depressed color="blue darken-4 white--text" v-on:click="addSslField()">
													<v-icon>mdi-plus</v-icon>
													Add more
												</v-btn>
											</v-col>
										</v-row>
									</template>
								</template>
							</v-tab-item>

							<v-tab-item value="web">
								<template v-if="project.checkbox_type.length && project.checkbox_type.includes('web')">
									<v-row class="px-4">
										<v-col
											lg="12"
											cols="12"
											class="my-auto px-0"
											style="font-size: 18px; color: black; font-weight: 600"
										>
											THIS SERVICE IS DONE BY BTHRUST UNDER THE PACKAGE PRICE OF SGD
											<span style="color: red">{{ formatMoney(project.cost) }}</span> ONLY AND NOT CHARGED
											SEPARATELY</v-col
										>
									</v-row>
									<!-- <div class="form-grp-title d-flex">
											<v-row class="px-4">
												<v-col lg="4" cols="12" class="my-auto px-0"> Entity </v-col>

												<v-col lg="8" class="my-auto py-0">
													<v-radio-group
														v-model="project.web[0].company_type"
														readonly
														row
														hide-details
														class="mt-0"
													>
														<v-radio value="bthrust"></v-radio>
														<label for="" class="ml-n4 mr-4"
															><img
																style="max-width: 35px; height: 22px"
																:src="$assetURL('media/project-logos/bthrust-icon.png')"
																alt=""
															/>
															Bthrust</label
														>
														<v-radio value="genic-solution"></v-radio>
														<label for="" class="ml-n4 mr-4"
															><img
																style="max-width: 35px; height: 22px"
																:src="$assetURL('media/project-logos/genic.png')"
																alt=""
															/>
															Genic Solution</label
														>
													</v-radio-group>
												</v-col>
											</v-row>
										</div> -->
									<template v-if="getValue(project, 'web.0.type') == 'bthrust'">
										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label">Web Url</label>
											</v-col>
											<v-col lg="8" class="my-auto py-0">
												<v-text-field
													outlined
													hide-details
													placeholder="Web Url"
													v-model="project.web[0].domain"
													:rules="[vrules.validUrl(project.web[0].domain, 'Domain', true)]"
												/>
												<!-- :rules="[vrules.validUrl(value, Title, required ? false : true )]" -->
											</v-col>
										</v-row>
										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label">CMS TYPE</label>
											</v-col>
											<v-col lg="8" class="my-auto py-0">
												<v-autocomplete
													:items="cmsType"
													item-text="name"
													item-value="name"
													hide-details
													outlined
													placeholder="CMS TYPE"
													v-model="project.web[0].cms"
													v-on:change="() => dateFormated(project.web[0], 'web')"
												>
												</v-autocomplete>
												<!-- <v-text-field outlined hide-details placeholder="CMS" v-model="project.web[0].cms" /> -->
											</v-col>
										</v-row>
										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label">CMS URL</label>
											</v-col>
											<v-col lg="8" class="my-auto py-0">
												<v-text-field
													outlined
													hide-details
													placeholder="CMS URL"
													v-model="project.web[0].cms_url"
													:rules="[vrules.validUrl(project.web[0].cms_url, 'CMS URL', true)]"
												/>
											</v-col>
										</v-row>

										<v-row class="px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label">Web Period</label>
											</v-col>
											<v-col lg="2" class="my-auto py-0"
												><label class="input-label required">Duration</label></v-col
											>
											<v-col lg="3" class="my-auto py-0"><label class="input-label">Start Date</label></v-col>
											<v-col lg="3" class="my-auto py-0"><label class="input-label">End Date</label></v-col>
										</v-row>
										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0"> </v-col>
											<v-col lg="2" class="my-auto py-0">
												<v-autocomplete
													:items="months"
													item-text="text"
													item-value="value"
													hide-details
													outlined
													placeholder="Month"
													v-model="project.web[0].duration"
													v-on:change="() => dateFormated(project.web[0], 'web')"
												>
												</v-autocomplete>
											</v-col>
											<v-col lg="3" class="my-auto py-0">
												<DatePicker
													hide-details
													hideTopMargin
													clearable
													:disabled="pageLoading"
													:loading="pageLoading"
													id="start-date"
													placeholder="Start Date"
													v-model="project.web[0].start_date"
													v-on:change="() => dateFormated(project.web[0], 'web')"
												>
												</DatePicker>
											</v-col>
											<v-col lg="3" class="my-auto py-0">
												<DatePicker
													hide-details
													clearable
													hideTopMargin
													readonly
													:disabled="pageLoading"
													:loading="pageLoading"
													id="start-date"
													placeholder="End Date"
													v-model="project.web[0].end_date"
													:min-date="getValue(disableDates, 'web') ? getValue(disableDates, 'web.0') : undefined"
												></DatePicker>
											</v-col>
										</v-row>
										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label">Remark </label>
											</v-col>

											<v-col lg="8" class="my-auto py-0">
												<TextAreaInput
													:rows="4"
													dense
													ref="comment"
													:word-limit="200"
													hide-details
													v-model="project.web[0].remark"
													placeholder="Enter Remark"
													:disabled="pageLoading"
													:loading="pageLoading"
												></TextAreaInput>
											</v-col>
										</v-row>
									</template>
								</template>
							</v-tab-item>

							<v-tab-item value="domain">
								<template v-if="project.checkbox_type.length && project.checkbox_type.includes('domain')">
									<template v-if="getValue(project, 'domain.0.type') == 'bthrust'">
										<v-row class="px-4">
											<v-col
												lg="12"
												cols="12"
												class="my-auto px-0"
												style="font-size: 18px; color: black; font-weight: 600"
											>
												THIS SERVICE IS DONE BY BTHRUST UNDER THE PACKAGE PRICE OF SGD
												<span style="color: red">{{ formatMoney(project.cost) }}</span> ONLY AND NOT CHARGED
												SEPARATELY</v-col
											>
										</v-row>

										<div v-for="(domain, index) in project.domain" :key="`project_domain_tab_${index + 1}`">
											<v-divider v-if="index > 0"></v-divider>
											<!-- <div class="form-grp-title d-flex">
													<v-row class="px-4">
														<v-col lg="4" cols="12" class="my-auto px-0"> Entity</v-col>
														<v-col lg="8" class="my-auto py-0">
															<v-radio-group v-model="domain.company_type" readonly row hide-details class="mt-0">
																<v-radio value="bthrust"></v-radio>
																<label for="" class="ml-n4 mr-4"
																	><img
																		style="max-width: 35px; height: 22px"
																		:src="$assetURL('media/project-logos/bthrust-icon.png')"
																		alt=""
																	/>
																	Bthrust</label
																>
																<v-radio value="genic-solution"></v-radio>
																<label for="" class="ml-n4 mr-4"
																	><img
																		style="max-width: 35px; height: 22px"
																		:src="$assetURL('media/project-logos/genic.png')"
																		alt=""
																	/>
																	Genic Solution</label
																>
															</v-radio-group>
														</v-col>
													</v-row>
												</div> -->
											<v-row class="mb-3 px-4">
												<v-col lg="4" cols="12" class="my-auto py-0">
													<label class="input-label">Domain Name</label>
												</v-col>
												<v-col lg="8" class="my-auto py-0">
													<!-- <v-text-field
														outlined
														hide-details
														placeholder="Domain Name"
														v-model="domain.name"
														:readonly="domainFieldDisabled(index)"
														@focus="onfocus"
														:rules="urlRules"
														:class="{
															required: !domain.name,
														}"
													>
													</v-text-field> -->
													<v-text-field
														outlined
														hide-details
														placeholder="Domain Name"
														v-model="domain.name"
														@focus="onfocus"
														:rules="urlRules"
														:class="{
															required: !domain.name,
														}"
													>
													</v-text-field>
												</v-col>
											</v-row>

											<v-row class="mb-3 px-4">
												<v-col lg="4" cols="12" class="my-auto py-0">
													<label class="input-label">Service Provider</label>
												</v-col>
												<v-col lg="8" class="my-auto py-0">
													<!-- <v-text-field
																outlined
																hide-details
																placeholder="Service Provider"
																v-model="domain.service_provider"
																:items="serverProvider"
															>
															</v-text-field> -->

													<v-autocomplete
														:items="getServiceProvider('is_domain')"
														item-text="text"
														item-value="value"
														hide-details
														outlined
														placeholder="Service Provider"
														v-model="domain.service_provider"
													>
													</v-autocomplete>
													<v-tooltip right v-if="false">
														<template #activator="{ on, attrs }">
															<v-icon
																link
																v-on="on"
																v-bind="attrs"
																class="input-addon"
																:color="'primary'"
																v-on:click="serviceProviderDrawer = true"
															>
																mdi-cog
															</v-icon>
														</template>
														<span>Service Provider Settings</span>
													</v-tooltip>
													<CreateServiceProvider
														:title="'Service Provider'"
														:type-id="50"
														v-if="serviceProviderDrawer"
														:drawer="serviceProviderDrawer"
														@close="serviceProviderDrawer = false"
														v-on:success="
															(serviceProviderDrawer = false), getSettings();
															deleteSPType = `domain:${index}`;
														"
													></CreateServiceProvider>
												</v-col>
											</v-row>

											<v-row class="px-4">
												<v-col lg="4" cols="12" class="my-auto py-0">
													<label class="input-label">Domain Period</label>
												</v-col>
												<v-col lg="2" class="my-auto py-0"
													><label class="input-label required">Duration</label></v-col
												>
												<v-col lg="3" class="my-auto py-0"><label class="input-label">Start Date</label></v-col>
												<v-col lg="3" class="my-auto py-0"><label class="input-label">End Date</label></v-col>
											</v-row>

											<v-row class="mb-3 px-4">
												<v-col lg="4" cols="12" class="my-auto py-0"> </v-col>
												<v-col lg="2" class="my-auto py-0">
													<v-autocomplete
														:items="months"
														item-text="text"
														item-value="value"
														hide-details
														outlined
														placeholder="Month"
														v-model="domain.duration"
														v-on:change="() => dateFormated(domain, 'domain')"
													>
													</v-autocomplete>
												</v-col>
												<v-col lg="3" class="my-auto py-0">
													<DatePicker
														hide-details
														clearable
														:disabled="pageLoading"
														hideTopMargin
														:loading="pageLoading"
														id="start-date"
														placeholder="Start Date"
														v-model="domain.start_date"
														v-on:change="() => dateFormated(domain, index, 'domain')"
													>
													</DatePicker>
												</v-col>
												<v-col lg="3" class="my-auto py-0">
													<DatePicker
														hide-details
														hideTopMargin
														clearable
														readonly
														:disabled="pageLoading"
														:loading="pageLoading"
														id="start-date"
														placeholder="End Date"
														:min-date="
															getValue(disableDates, 'domain') && getValue(disableDates, `domain`).length
																? getValue(disableDates, `domain.${index}`)
																: undefined
														"
														v-model="domain.end_date"
													></DatePicker>
												</v-col>
											</v-row>

											<v-row class="mb-3 px-4">
												<v-col lg="4" cols="12" class="my-auto py-0">
													<label class="input-label">Remark </label>
												</v-col>

												<v-col lg="8" class="my-auto py-0">
													<TextAreaInput
														:rows="4"
														dense
														ref="comment"
														:word-limit="200"
														hide-details
														v-model="domain.remark"
														placeholder="Enter Remark"
														:disabled="pageLoading"
														:loading="pageLoading"
													></TextAreaInput>
												</v-col>
											</v-row>

											<v-row class="mb-3 px-4">
												<v-col lg="4" cols="12" class="my-auto py-0">
													<label class="input-label">Notification Person</label>
												</v-col>

												<v-col lg="8" class="my-auto py-0">
													<AutoCompleteInput
														hide-details
														multiple
														hide-top-margin
														v-model="domain.notification_person"
														:items.sync="users"
														:disabled="pageLoading"
														:loading="pageLoading"
														custom-class="mt-0"
														item-text="display_name"
														item-value="id"
														placeholder="Notification Person"
													>
														<template #noStyleItem="{ li, attrs }">
															<div class="w-100 d-flex align-center justify-space-between py-1">
																<div class="d-flex align-center">
																	<v-avatar size="36" class="mr-2">
																		<v-img v-if="li.profile_img" :src="li.profile_img" />
																		<v-img v-else src="/media/users/blank.png" />
																	</v-avatar>
																	<div>
																		<div class="fw-600 text-uppercase">
																			{{ li.display_name }}
																		</div>
																		<div class="text-muted fw-500">
																			{{ li.email }}
																		</div>
																	</div>
																</div>
																<v-icon color="green" v-if="attrs['aria-selected'] == 'true'"
																	>mdi-check-circle-outline</v-icon
																>
															</div>
														</template>
														<template #selectedItem="{ item }">
															<v-chip class="px-2" color="blue lighten-5">
																<v-avatar class="mr-2" v-if="item.profile_img">
																	<v-img :src="item.profile_img" />
																</v-avatar>
																<div class="fw-600 text-uppercase">
																	{{ item.display_name }}
																</div>
															</v-chip>
														</template>
													</AutoCompleteInput>
												</v-col>
											</v-row>

											<v-row class="mb-3 px-4" v-if="index > 0">
												<v-col md="12">
													<v-btn
														tile
														depressed
														color="red white--text"
														:disabled="domainFieldDisabled(index)"
														v-on:click="removeDomainField(index)"
													>
														<v-icon>mdi-delete</v-icon>
														Remove
													</v-btn>
												</v-col>
											</v-row>
										</div>
										<!-- ADD MORE BUTTON DOMAIN -->
										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<v-btn tile depressed color="blue darken-4 white--text" v-on:click="addDomainField()">
													<v-icon>mdi-plus</v-icon>
													Add more
												</v-btn>
											</v-col>
										</v-row>
									</template>
								</template>
							</v-tab-item>

							<v-tab-item value="maintenance">
								<template
									v-if="project.checkbox_type.length && project.checkbox_type.includes('maintenance')"
								>
									<!-- <div class="form-grp-title d-flex">
												<v-row class="px-4">
													<v-col lg="4" cols="12" class="my-auto px-0"> Maintenance</v-col>
													<v-col lg="8" class="my-auto py-0">
														<template
															v-if="
																project &&
																project.maintenance &&
																project.maintenance[0] &&
																project.maintenance[0].type
															"
														>
															<v-radio-group v-model="project.maintenance[0].type" row hide-details class="mt-0">
																<v-radio label="Client" value="client"></v-radio>
																<v-radio label="Bthrust" value="bthrust"></v-radio>
															</v-radio-group>
														</template>
													</v-col>
												</v-row>
											</div> -->
									<v-row class="px-4">
										<v-col
											lg="12"
											cols="12"
											class="my-auto px-0"
											style="font-size: 18px; color: black; font-weight: 600"
										>
											THIS SERVICE IS DONE BY BTHRUST UNDER THE PACKAGE PRICE OF SGD
											<span style="color: red">{{ formatMoney(project.cost) }}</span> ONLY AND NOT CHARGED
											SEPARATELY</v-col
										>
									</v-row>
									<!-- <div class="form-grp-title d-flex">
											<v-row class="px-4">
												<v-col lg="4" cols="12" class="my-auto px-0"> Entity</v-col>

												<v-col lg="8" class="my-auto py-0">
													<v-radio-group
														v-model="project.maintenance[0].company_type"
														row
														readonly
														hide-details
														class="mt-0"
													>
														<v-radio value="bthrust"></v-radio>
														<label for="" class="ml-n4 mr-4"
															><img
																style="max-width: 35px; height: 22px"
																:src="$assetURL('media/project-logos/bthrust-icon.png')"
																alt=""
															/>
															Bthrust</label
														>
														<v-radio value="genic-solution"></v-radio>
														<label for="" class="ml-n4 mr-4"
															><img
																style="max-width: 35px; height: 22px"
																:src="$assetURL('media/project-logos/genic.png')"
																alt=""
															/>
															Genic Solution</label
														>
													</v-radio-group>
												</v-col>
											</v-row>
										</div> -->
									<template v-if="getValue(project, 'maintenance.0.type') == 'bthrust'">
										<v-row class="px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label">Maintenance Period</label>
											</v-col>

											<v-col lg="2" class="my-auto py-0"
												><label class="input-label required">Duration</label></v-col
											>
											<v-col lg="3" class="my-auto py-0"><label class="input-label">Start Date</label></v-col>
											<v-col lg="3" class="my-auto py-0"><label class="input-label">End Date</label></v-col>
										</v-row>
										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0"> </v-col>
											<v-col lg="2" class="my-auto py-0">
												<v-autocomplete
													:items="months"
													item-text="text"
													item-value="value"
													hide-details
													outlined
													placeholder="Month"
													v-model="project.maintenance[0].duration"
													v-on:change="() => dateFormated(project.maintenance[0], 'maintenance')"
												>
												</v-autocomplete>
											</v-col>
											<v-col lg="3" class="my-auto py-0">
												<DatePicker
													hide-details
													hideTopMargin
													clearable
													:disabled="pageLoading"
													:loading="pageLoading"
													id="start-date"
													placeholder="Start Date"
													v-model="project.maintenance[0].start_date"
													v-on:change="() => dateFormated(project.maintenance[0], 'maintenance')"
												>
												</DatePicker>
											</v-col>

											<v-col lg="3" class="my-auto py-0">
												<DatePicker
													hide-details
													hideTopMargin
													clearable
													readonly
													:disabled="pageLoading"
													:loading="pageLoading"
													id="start-date"
													placeholder="End Date"
													v-model="project.maintenance[0].end_date"
												></DatePicker>
											</v-col>
										</v-row>
										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label">Web Url</label>
											</v-col>
											<v-col lg="8" class="my-auto py-0">
												<v-text-field
													outlined
													hide-details
													placeholder="Web Url"
													v-model="project.maintenance[0].domain"
													:rules="[vrules.validUrl(project.maintenance[0].domain, 'Domain', true)]"
												/>
											</v-col>
										</v-row>
										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label">Remark</label>
											</v-col>
											<v-col lg="8" class="my-auto py-0">
												<TextAreaInput
													:rows="4"
													dense
													ref="comment"
													:word-limit="200"
													hide-details
													v-model="project.maintenance[0].remark"
													placeholder="Enter Remark"
													:disabled="pageLoading"
													:loading="pageLoading"
												></TextAreaInput>
											</v-col>
										</v-row>
									</template>
								</template>
							</v-tab-item>

							<v-tab-item value="software" class="pt-2">
								<template v-if="project.checkbox_type.length && project.checkbox_type.includes('software')">
									<div class="form-grp-title d-flex">Sales</div>

									<v-row class="mb-3 px-4">
										<v-col lg="4" cols="12" class="my-auto py-0">
											<label class="input-label required">Sale Date</label>
										</v-col>
										<v-col lg="8" class="my-auto py-0">
											<DatePicker
												hide-details
												clearable
												hideTopMargin
												:disabled="pageLoading"
												:loading="pageLoading"
												id="sale-date"
												placeholder="Sale Date"
												v-model="project.software[0].sale_date"
												:rules="[vrules.required(project.software[0].sale_date, 'Sale Date')]"
												:class="{
													required: !project.software[0].sale_date,
												}"
											>
											</DatePicker>
										</v-col>
									</v-row>

									<v-row class="mb-3 px-4">
										<v-col lg="4" cols="12" class="my-auto py-0">
											<label class="input-label">Live (Expected)</label>
										</v-col>
										<v-col lg="8" class="my-auto py-0">
											<DatePicker
												hide-details
												clearable
												hideTopMargin
												:disabled="pageLoading"
												:loading="pageLoading"
												id="contract-date"
												placeholder="Live (Expected)"
												v-model="project.software[0].live_expected"
											>
											</DatePicker>
										</v-col>
									</v-row>

									<v-row class="mb-3 px-4">
										<v-col lg="4" cols="12" class="my-auto py-0">
											<label class="input-label">Live (Actual)</label>
										</v-col>
										<v-col lg="8" class="my-auto py-0">
											<DatePicker
												hide-details
												clearable
												hideTopMargin
												:disabled="pageLoading"
												:loading="pageLoading"
												id="actual-live-date"
												placeholder="Live (Actual)"
												v-model="project.software[0].live_actual"
												@change="() => dateFormated(project.software[0], 0, 'software')"
											>
											</DatePicker>
										</v-col>
									</v-row>

									<v-row class="mb-3 px-4">
										<v-col lg="4" cols="12" class="my-auto py-0">
											<label class="input-label required">Contract Period</label>
										</v-col>
										<v-col lg="8" class="my-auto py-0">
											<v-row>
												<v-col cols="4" md="6" class="py-0">
													<v-autocomplete
														:items="months"
														item-text="text"
														item-value="value"
														hide-details
														outlined
														placeholder="Months"
														v-model="project.software[0].duration"
														:rules="[vrules.required(project.software[0].duration, 'Months')]"
														@change="() => dateFormated(project.software[0], 0, 'software')"
													>
														<!-- v-model="contract.customer_services[index].total_time" -->
													</v-autocomplete>
												</v-col>
												<div class="my-auto">+</div>
												<v-col cols="4" md="5" class="py-0 extend-picker">
													<NumberInput
														hide-details
														:disabled="pageLoading"
														:loading="pageLoading"
														id="time-free"
														placeholder="Free"
														hideTopMargin
														v-model="project.software[0].time_free"
														@input="() => dateFormated(project.software[0], 0, 'software')"
													></NumberInput>
												</v-col>
											</v-row>
										</v-col>
									</v-row>

									<v-row class="mb-3 px-4">
										<v-col lg="4" cols="12" class="my-auto py-0">
											<label class="input-label">Is Grant</label>
										</v-col>
										<v-col lg="8" class="my-auto py-0">
											<v-checkbox
												:false-value="false"
												:true-value="true"
												hide-details
												class="m-0 p-0"
												:ripple="false"
												v-model="project.software[0].is_grant"
											></v-checkbox>
										</v-col>
									</v-row>

									<template v-if="project.software[0].is_grant">
										<div class="form-grp-title d-flex">Grant</div>
										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0"></v-col>

											<v-col lg="8" class="my-auto py-0">
												<v-radio-group
													class="mt-0"
													row
													hide-details
													dense
													v-model="project.software[0].grant_type"
												>
													<v-radio
														v-for="p in [
															{ title: 'EDG', value: 'edg' },
															{ title: 'PSG', value: 'psg' },
														]"
														:key="p.title + p.value"
														:label="p.title"
														:value="p.value"
													>
													</v-radio>
												</v-radio-group>
											</v-col>
										</v-row>
										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label">LOA Date</label>
											</v-col>

											<v-col lg="8" class="my-auto py-0">
												<DatePicker
													hide-details
													clearable
													hideTopMargin
													:disabled="pageLoading"
													:loading="pageLoading"
													id="loa-date"
													placeholder="LOA Date"
													v-model="project.software[0].loa_date"
												>
												</DatePicker>
											</v-col>
										</v-row>
										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label">Grant Filling Date</label>
											</v-col>

											<v-col lg="8" class="my-auto py-0">
												<DatePicker
													hide-details
													clearable
													hideTopMargin
													:disabled="pageLoading"
													:loading="pageLoading"
													id="filling-date"
													placeholder="Grant Filling Date"
													v-model="project.software[0].filling_date"
												>
												</DatePicker>
											</v-col>
										</v-row>
										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label">System Live</label>
											</v-col>

											<v-col lg="8" class="my-auto py-0">
												<DatePicker
													hide-details
													clearable
													hideTopMargin
													:disabled="pageLoading"
													:loading="pageLoading"
													id="system-live"
													placeholder="System Live"
													v-model="project.software[0].system_live"
												>
												</DatePicker>
											</v-col>
										</v-row>
										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label">Claim Deadline</label>
											</v-col>

											<v-col lg="8" class="my-auto py-0">
												<DatePicker
													hide-details
													clearable
													hideTopMargin
													:disabled="pageLoading"
													:loading="pageLoading"
													id="claim-deadline"
													placeholder="Claim Deadline"
													v-model="project.software[0].claim_deadline"
												>
												</DatePicker>
											</v-col>
										</v-row>
									</template>

									<!--  -->

									<div class="form-grp-title d-flex">Recurring</div>

									<!-- <v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label required">Expected Live Date</label>
											</v-col>
											<v-col lg="8" class="my-auto py-0">
												<DatePicker
													hide-details
													clearable
													hideTopMargin
													:disabled="pageLoading"
													:loading="pageLoading"
													id="contract-date"
													placeholder="Expected Live Date"
													v-model="project.software[0].live_expected"
													:rules="vrules.required(project.software[0].live_expected, 'Expected Live Date')"
												>
												</DatePicker>
											</v-col>
										</v-row> -->

									<!-- <v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label">Contract Period</label>
											</v-col>
											<v-col lg="8" class="my-auto py-0">
												<v-row>
													<v-col cols="4" md="6" class="py-0">
														<v-autocomplete
															:items="months"
															item-text="text"
															item-value="value"
															hide-details
															outlined
															placeholder="Months"
															v-model="project.software[0].duration"
															:rules="vrules.required(project.software[0].duration, 'Months')"
														>
														</v-autocomplete>
													</v-col>
													<div class="my-auto">+</div>
													<v-col cols="4" md="5" class="py-0 extend-picker">
														<v-text-field
															outlined
															hide-details
															placeholder="Free"
															v-model="project.software[0].time_free"
														>
														</v-text-field>
													</v-col>
												</v-row>
											</v-col>
										</v-row> -->

									<v-row class="mb-3 px-4">
										<v-col lg="4" cols="12" class="my-auto py-0">
											<label class="input-label">Start Date</label>
										</v-col>

										<v-col lg="8" class="my-auto py-0">
											<DatePicker
												hide-details
												clearable
												hideTopMargin
												readonly
												:disabled="pageLoading"
												:loading="pageLoading"
												id="contract-date"
												placeholder="Start Date"
												v-model="project.software[0].recurring_date"
											>
											</DatePicker>
										</v-col>
									</v-row>

									<v-row class="mb-3 px-4">
										<v-col lg="4" cols="12" class="my-auto py-0"> Users </v-col>

										<v-col lg="8" class="my-auto py-0">
											<!-- <NumberInput
												hide-details
												:disabled="pageLoading"
												:loading="pageLoading"
												:id="`checkout-duration`"
												placeholder="Users"
												hideTopMargin
												v-model="project.software[0].no_of_users"
												@input="calculateUsersAndCost($event, 'users')"
											></NumberInput> -->
											<!-- <NumberInput
												hide-details
												:disabled="pageLoading"
												:loading="pageLoading"
												:id="`checkout-duration`"
												placeholder="Users"
												outlined
												v-model="project.software[0].no_of_users"
												@input="calculateUsersAndCost($event, 'users')"
												@keyup="allowInput($event, ['number'])"
												@paste="disablePaste"
											></NumberInput> -->

											<v-text-field
												type="number"
												hide-details
												:disabled="pageLoading"
												:loading="pageLoading"
												:id="`checkout-duration`"
												placeholder="Users"
												outlined
												v-model="project.software[0].no_of_users"
												@input="calculateUsersAndCost($event, 'users')"
												@keypress="allowInput($event, ['number'])"
												@paste="disablePaste"
											></v-text-field>
										</v-col>
									</v-row>

									<v-row class="mb-3 px-4">
										<v-col lg="4" cols="12" class="my-auto py-0">Total Cost (SGD)</v-col>

										<v-col lg="8" class="my-auto py-0">
											<v-row>
												<v-col md="6" class="py-0">
													<v-text-field
														type="number"
														outlined
														hide-details
														:disabled="pageLoading"
														:loading="pageLoading"
														:id="`checkout-duration`"
														placeholder="Total Cost (SGD)"
														prependInnerIcon="mdi-currency-usd"
														hideTopMargin
														v-model="project.software[0].total_cost"
														@input="calculateUsersAndCost($event, 'total')"
														@keypress="limitDecimal($event, project.software[0].total_cost)"
													></v-text-field>
													<!-- <NumberInput
														hide-details
														:disabled="pageLoading"
														:loading="pageLoading"
														:id="`checkout-duration`"
														placeholder="Total Cost (SGD)"
														prependInnerIcon="mdi-currency-usd"
														hideTopMargin
														v-model="project.software[0].total_cost"
														@input="calculateUsersAndCost($event, 'total')"
													></NumberInput> -->
												</v-col>
												<div class="my-auto">+</div>
												<v-col md="5" class="py-0 extend-picker">
													<SelectInput
														hide-details
														:items="[
															{ title: 'Monthly', value: 'monthly' },
															{ title: 'Yearly', value: 'yearly' },
														]"
														:disabled="pageLoading"
														customClass=""
														:loading="pageLoading"
														placeholder="Duration"
														item-text="title"
														item-value="value"
														v-model="project.software[0].time_interval"
													></SelectInput>
												</v-col>
											</v-row>
										</v-col>
									</v-row>

									<v-row class="mb-3 px-4">
										<v-col lg="4" cols="12" class="my-auto py-0">
											<label class="input-label">Cost Per User (SGD)</label>
										</v-col>

										<v-col lg="8" class="my-auto py-0">
											<v-row>
												<v-col md="6" class="py-0">
													<v-text-field
														type="number"
														hide-details
														outlined
														:disabled="pageLoading"
														:loading="pageLoading"
														:id="`checkout-duration`"
														placeholder="Cost"
														prependInnerIcon="mdi-currency-usd"
														hideTopMargin
														v-model="project.software[0].cost_per_user"
														@input="calculateUsersAndCost($event, 'cost')"
														@keypress="limitDecimal($event, project.software[0].cost_per_user)"
													></v-text-field>

													<!-- <NumberInput
														hide-details
														:disabled="pageLoading"
														:loading="pageLoading"
														:id="`checkout-duration`"
														placeholder="Cost"
														prependInnerIcon="mdi-currency-usd"
														hideTopMargin
														v-model="project.software[0].cost_per_user"
														@input="calculateUsersAndCost($event, 'cost')"
													></NumberInput> -->
												</v-col>
												<div class="my-auto">+</div>
												<v-col md="5" class="py-0 extend-picker">
													<SelectInput
														hide-details
														:items="[
															{ title: 'Monthly', value: 'monthly' },
															{ title: 'Yearly', value: 'yearly' },
														]"
														:disabled="pageLoading"
														customClass=""
														:loading="pageLoading"
														placeholder="Duration"
														item-text="title"
														item-value="value"
														v-model="project.software[0].time_interval"
													></SelectInput>
												</v-col>
											</v-row>
										</v-col>
									</v-row>

									<v-row class="mb-3 px-4">
										<v-col lg="4" cols="12" class="my-auto py-0">
											<label class="input-label">Remark </label>
										</v-col>

										<v-col lg="8" class="my-auto py-0">
											<TextAreaInput
												:rows="4"
												dense
												ref="comment"
												:word-limit="200"
												hide-details
												placeholder="Enter Remark"
												:disabled="pageLoading"
												:loading="pageLoading"
												v-model="project.software[0].remark"
											></TextAreaInput>
										</v-col>
									</v-row>

									<v-row class="mb-3 px-4">
										<v-col lg="4" cols="12" class="my-auto py-0">
											<label class="input-label required">Software Type</label>
										</v-col>
										<v-col lg="8" class="my-auto py-0 pr-10">
											<v-autocomplete
												hide-details
												outlined
												:items="software_types"
												placeholder="Software Type"
												item-text="name"
												item-value="value"
												v-model="project.software[0].software_type"
												:rules="[vrules.required(project.software[0].software_type, 'Software Type')]"
												:class="{
													required: !project.software[0].software_type,
												}"
											>
											</v-autocomplete>
											<v-tooltip right>
												<template #activator="{ on, attrs }">
													<v-icon
														link
														v-on="on"
														v-bind="attrs"
														class="input-addon"
														:color="'primary'"
														v-on:click="softwareTypeDrawer = true"
													>
														mdi-cog
													</v-icon>
												</template>
												<span>Software Type Settings</span>
											</v-tooltip>
											<CreateSoftwareType
												v-if="softwareTypeDrawer"
												:title="'Software Type'"
												:drawer="softwareTypeDrawer"
												@close="softwareTypeDrawer = false"
												@success="(softwareTypeDrawer = false), getSettings()"
											></CreateSoftwareType>
										</v-col>
									</v-row>

									<v-row class="mb-3 px-4">
										<v-col lg="4" cols="12" class="my-auto py-0">
											<label class="input-label">Tags</label>
										</v-col>
										<v-col lg="8" class="my-auto py-0">
											<TagsAutoSearch v-model="project.software[0].tags" />
										</v-col>
									</v-row>

									<div class="form-grp-title d-flex">Server</div>

									<v-row class="mb-3 px-4">
										<v-col lg="4" cols="12" class="my-auto py-0">
											<label class="input-label">Service Provider</label>
										</v-col>
										<v-col lg="8" class="my-auto py-0 position-relative">
											<v-autocomplete
												:items="getServiceProvider('is_hosting')"
												item-text="text"
												item-value="value"
												hide-details
												outlined
												placeholder="Service Provider"
												v-model="project.software[0].service_provider"
											>
											</v-autocomplete>
										</v-col>
									</v-row>

									<v-row class="mb-3 px-4">
										<v-col lg="4" cols="12" class="my-auto py-0">
											<label class="input-label">Server</label>
										</v-col>

										<v-col lg="8" class="my-auto py-0">
											<v-autocomplete
												hide-details
												outlined
												:items.sync="getServerWeb"
												placeholder="Server"
												item-text="name"
												item-value="id"
												v-model="project.software[0].server"
											>
											</v-autocomplete>
											<!-- <v-tooltip right>
												<template #activator="{ on, attrs }">
													<v-icon
														link
														v-on="on"
														v-bind="attrs"
														class="input-addon"
														:color="'primary'"
														v-on:click="serverTypeDrawer = true"
													>
														mdi-cog
													</v-icon>
												</template>
												<span>Server Settings</span>
											</v-tooltip>
											<CreateServer
												v-if="serverTypeDrawer"
												:drawer="serverTypeDrawer"
												@close="serverTypeDrawer = false"
												:title="'Server'"
												:type-id="51"
												v-on:success="(serverTypeDrawer = false), getSettings()"
											></CreateServer> -->
										</v-col>
									</v-row>

									<div class="form-grp-title d-flex">Support</div>

									<v-row class="mb-3 px-4">
										<v-col lg="4" cols="12" class="my-auto py-0">
											<label class="input-label">Project Manager (SG)</label>
										</v-col>

										<v-col lg="8" class="my-auto py-0">
											<v-autocomplete
												hide-details
												outlined
												:items="users"
												placeholder="Project Manager (SG)"
												item-text="display_name"
												item-value="id"
												v-model="project.software[0].project_manager_sg"
											>
												<!-- :rules="[vrules.required(contract.customer, 'Customer')]" -->
												<!-- :class="{
														required: !contract.customer,
													}" -->
											</v-autocomplete>
										</v-col>
									</v-row>

									<v-row class="mb-3 px-4">
										<v-col lg="4" cols="12" class="my-auto py-0">
											<label class="input-label">Primary Tech</label>
										</v-col>

										<v-col lg="8" class="my-auto py-0">
											<v-autocomplete
												hide-details
												outlined
												:items="primary_support_users"
												placeholder="Primary Tech Support"
												item-text="display_name"
												item-value="id"
												v-model="project.software[0].primary_support"
												@input="support_user_select($event, 'primary')"
											>
												<!-- :rules="[vrules.required(contract.customer, 'Customer')]" -->
												<!-- :class="{
														required: !contract.customer,
													}" -->
											</v-autocomplete>
										</v-col>
									</v-row>

									<v-row class="mb-3 px-4">
										<v-col lg="4" cols="12" class="my-auto py-0">
											<label class="input-label">Secondary Tech</label>
										</v-col>

										<v-col lg="8" class="my-auto py-0">
											<v-autocomplete
												hide-details
												outlined
												:items="secondary_support_users"
												placeholder="Secondary Tech Support"
												item-text="display_name"
												item-value="id"
												v-model="project.software[0].secondary_support"
												@input="support_user_select($event, 'secondary')"
											>
												<!-- :rules="[vrules.required(contract.customer, 'Customer')]" -->
												<!-- :class="{
														required: !contract.customer,
													}" -->
											</v-autocomplete>
										</v-col>
									</v-row>
								</template>
							</v-tab-item>
						</v-tabs-items>
					</v-col>
				</v-row>
			</v-form>
		</div>
	</v-sheet>
</template>
<style>
.extend-picker .v-input {
	min-width: 118.5%;
}
</style>
<script>
import { mapGetters } from "vuex";
import { POST, GET, DELETE } from "@/core/services/store/request.module";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SET_SEARCH_BAR } from "@/core/services/store/listing.module";
import MainMixin from "@/core/mixins/main.mixin.js";
// import ListingMixin from "@/core/mixins/listing.mixin";
import SettingsMixin from "@/core/mixins/settings.mixin";
import MomentJS from "moment-timezone";
// import CreateServer from "@/view/components/create-server";
import CreateSoftwareType from "@/view/components/create-software-type";
import DatePicker from "@/view/components/DatePicker";
import TextAreaInput from "@/view/components/TextAreaInput";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import Chip from "@/view/components/Chip";
import NumberInput from "@/view/components/NumberInput";
import SelectInput from "@/view/components/SelectInput";
import TagsAutoSearch from "@/view/components/TagsAutoSearch";
import TextInput from "@/view/components/TextInput";
import { toSafeInteger, some, filter } from "lodash";
/* import { ProjectEventBus } from "@/core/lib/leave.form.lib"; */
import { ProjectEventBus } from "@/core/lib/project.form.lib";
import { cloneDeep, toString, isBoolean } from "lodash";
import objectPath from "object-path";
import RadioInputCustom from "@/view/components/RadioInputCustom";
export default {
	mixins: [MainMixin /*, ListingMixin*/, SettingsMixin],
	name: "Create-Project",
	props: {
		projectDialog: {
			type: Boolean,
			default: false,
		},

		customerId: {
			type: Number,
			default: 0,
		},
		/* projectId: {
			type: Number,
			default: 0,
		}, */
		// internal: {
		// 	type: Boolean,
		// 	default: false,
		// },
	},

	data() {
		return {
			pageTitle: "Create New Project",
			formValid: true,
			cmsType: [],
			serverid: "+new Date()",
			today: new Date(),
			date_menu: false,
			/* projectId: null, */
			restrictStartDateWhenRenew: null,
			contact_period_list: [
				{ value: 1, name: "Monthly" },
				{ value: 3, name: "Quarterly" },
				{ value: 2, name: "BI Monthly" },
				{ value: 6, name: "Half Yearly" },
				{ value: 12, name: "Yearly" },
			],
			disablePastDate: null,
			disablePastEndDate: null,
			settingsEndpoint: "project-setting",
			users: [],
			leadData: [],
			pageLoading: false,
			currencyList: [],
			//	openUploadModel:false,
			full_rating: 4,
			customer_id: 0,
			contract_id: 0,
			base_only_rating: 2,
			renew_rating: 3,
			projectId: 0,
			project_date_menu: false,
			seo_date_menu: false,
			seo_free_date_menu: false,
			seo_end_date_menu: false,
			sales_date_menu: false,
			dataTab: null,
			checkData: [],
			usersList: [],
			preHostingType: null,
			preSslType: null,
			preWebType: null,
			preDomainType: null,
			preMainType: null,
			preSeoType: null,
			lead_id: 0,
			checkpayload: false,
			months: [
				{ value: "1", text: "1" },
				{ value: "2", text: "2" },
				{ value: "3", text: "3" },
				{ value: "4", text: "4" },
				{ value: "5", text: "5" },
				{ value: "6", text: "6" },
				{ value: "7", text: "7" },
				{ value: "8", text: "8" },
				{ value: "9", text: "9" },
				{ value: "10", text: "10" },
				{ value: "11", text: "11" },
				{ value: "12", text: "12" },
				{ value: "13", text: "13" },
				{ value: "14", text: "14" },
				{ value: "15", text: "15" },
				{ value: "16", text: "16" },
				{ value: "17", text: "17" },
				{ value: "18", text: "18" },
				{ value: "19", text: "19" },
				{ value: "20", text: "20" },
				{ value: "21", text: "21" },
				{ value: "22", text: "22" },
				{ value: "23", text: "23" },
				{ value: "24", text: "24" },
			],
			invoice_months: [
				{ value: "1", text: "1" },
				{ value: "2", text: "2" },
				{ value: "3", text: "3" },
				{ value: "4", text: "4" },
				{ value: "5", text: "5" },
				{ value: "6", text: "6" },
				{ value: "7", text: "7" },
				{ value: "8", text: "8" },
				{ value: "9", text: "9" },
				{ value: "10", text: "10" },
				{ value: "11", text: "11" },
				{ value: "12", text: "12" },
				{ value: "13", text: "13" },
				{ value: "14", text: "14" },
				{ value: "15", text: "15" },
				{ value: "16", text: "16" },
				{ value: "17", text: "17" },
				{ value: "18", text: "18" },
				{ value: "19", text: "19" },
				{ value: "20", text: "20" },
				{ value: "21", text: "21" },
				{ value: "22", text: "22" },
				{ value: "23", text: "23" },
				{ value: "24", text: "24" },
			],
			payment_list: [
				/* { value: "1", text: "Yearly" },
				{ value: "2", text: "Half Yearly" },
				{ value: "3", text: "Monthly" }, */
			],
			target_list: [],
			salesPserson: [],
			customersId: 0,
			customerData: [],
			customerContract: [],
			/* project:{
				type:'seo',
				user:0,
			}, */
			convert_project: false,
			softwareTypeDrawer: false,
			serviceProviderDrawer: false,
			serverTypeDrawer: false,

			project: {
				total_invoice: 0,
				cost: 0,
				invoice_duration: "12",
				priod_month: 12,
				invoice_end_date: null,
				invoice_start_date: new Date().toISOString().substr(0, 10),
				user: 0,
				contract: 0,
				customer: 0,
				checkbox_type: [],
				billing_type: "single_billing",
				seo_type: "single",
				entity_type: null,
				barcode: null,
				service_option: null,
				hosting: [
					{
						cpanal_url: null,
						cost: 0,
						domain: null,

						invoice_duration: "12",
						priod_month: 12,
						invoice_end_date: null,
						invoice_start_date: new Date().toISOString().substr(0, 10),
						duration: "12",
						remark: null,
						start_date: null,
						end_date: null,
						cms: null,
						cms_url: null,
						server: null,
						service_provider: null,
						type: "bthrust",
						related_type: 0,
						is_filld: false,
					},
				],
				ssl: [
					{
						domain_name: null,
						server: null,
						service_provider: null,
						cost: 0,
						invoice_duration: "12",
						priod_month: 12,
						invoice_end_date: null,
						invoice_start_date: new Date().toISOString().substr(0, 10),
						type: "bthrust",
						duration: "12",
						remark: null,
						related_type: 0,
						start_date: null,
						end_date: null,
						end_date_readonly: null,
						notification_person: [],
					},
				],
				web: [
					{
						domain: null,
						remark: null,
						duration: "12",
						cost: 0,
						invoice_duration: "12",
						priod_month: 12,
						invoice_end_date: null,
						invoice_start_date: new Date().toISOString().substr(0, 10),
						cms: null,
						cms_url: null,
						type: "bthrust",
						related_type: 0,
						start_date: null,
						end_date: null,
					},
				],
				domain: [
					{
						name: null,
						service_provider: null,
						cost: 0,
						invoice_duration: "12",
						priod_month: 12,
						invoice_end_date: null,
						invoice_start_date: new Date().toISOString().substr(0, 10),
						type: "bthrust",
						related_type: 0,
						start_date: null,
						end_date: null,
						remark: null,
						duration: "12",
						notification_person: [],
					},
				],
				maintenance: [
					{
						type: "bthrust",
						duration: "12",
						cost: 0,
						invoice_duration: "12",
						priod_month: 12,
						invoice_end_date: null,
						invoice_start_date: new Date().toISOString().substr(0, 10),
						related_type: 0,
						start_date: null,
						end_date: null,
						remark: null,
					},
				],
				software: [
					{
						software_type: null,
						tags: null,
						sale_date: null,
						live_expected: null,
						live_actual: null,
						duration: "12",
						time_free: null,
						is_grant: false,

						service_provider: null,
						server: null,

						recurring_date: null,
						no_of_users: null,
						total_cost: null,
						cost_per_user: null,
						time_interval: "monthly",
						remark: null,

						project_manager_sg: null,
						primary_support: null,
						secondary_support: null,

						grant_type: "edg",
						filling_date: null,

						loa_date: null,
						system_live: null,
						claim_deadline: null,
					},
				],
				customer_services: [
					{
						id: 0,
						keyword_monthly: null,
						keyword_free: null,
						total_time: "12",
						project_manager: null,
						time_free: null,
						select_date: new Date().toISOString().substr(0, 10),
						total_time_end: null,
						time_free_end: null,
						cost: 0,
						invoice_duration: "12",
						priod_month: 12,
						invoice_end_date: null,
						invoice_start_date: new Date().toISOString().substr(0, 10),
						payment_terms: 0,
						remark: null,
						customer_urls: [
							{
								website_urls: null,
								url_target_google: 0,
								keyword_files: [
									{
										file: null,
										name: null,
									},
								],
							},
						],
					},
				],
			},

			primary_support_users: [],
			secondary_support_users: [],

			statusList: [],
			countryList: [],
			//fileList: [],
			files: [
				{
					file: null,
					name: null,
				},
			],
			phoneCodeList: [],
			projectTypeList: [
				{ value: "seo", name: "Seo" },
				{ value: "web", name: "Web" },
				{ value: "domain", name: "Domain" },
				{ value: "hosting", name: "Hosting" },
				{ value: "ssl", name: "SSL" },
				{ value: "maintenance", name: "Maintenance" },
			],
			designationList: [],
			serverProvider: [],
			serverWeb: [],
			fixServerWeb: [],
			p_index: null,
			c_index: null,
			urlRules: [
				(v) => !!v || "Domain is required",
				(v) =>
					v
						? /^(?!.*\.{2})(?!.*^\.)?(?:https?:\/\/)?(?:www\.)?(?!www\.)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\/[^\s]*)?$/.test(
								v
						  ) || "Domain must be valid"
						: false,
			],
			disableDates: {
				domain: [],
				ssl: [],
				web: [],
				seo: [],
				seo_free: [],
				hosting: [],
				maintenance: [],
			},
			copySdata: {},
			copyDdata: {},
			software_types: [],
			debounceTimer: null,
			project_renew: null,
			deleteSPType: null,
		};
	},
	watch: {
		projectId(projectId) {
			if (projectId) {
				this.getproject();
			}
		},
		moduleSettings(settings) {
			if (settings && Object.keys(settings).length) {
				this.serverProvider = settings.serverProvider;
				/* this.serverWeb = settings.server;
				if (this.serverWeb.length) {
					this.serverWeb = filter(this.serverWeb, (row) => row.provider_type == "company");
				} */
				this.fixServerWeb = settings.server;
				this.payment_list = settings.payment_list;
				this.target_list = [...settings.target_list];
				this.customerData = settings.customers;
				this.users = settings.users;
				this.primary_support_users = settings.users;
				this.secondary_support_users = settings.users;
				this.software_types = settings.software;
			}
		},
		serverProvider: {
			deep: true,
			handler() {
				this.deleteCheck();
			},
		},
		software_types: {
			deep: true,
			handler() {
				this.deleteSoftTypeCheck();
			},
		},
	},
	/* props:{
		customerId: {
			type: Number,
			default: 0,
		},
	}, */
	components: {
		// CreateServer,
		TagsAutoSearch,
		DatePicker,
		TextAreaInput,
		TextInput,
		Chip,
		AutoCompleteInput,
		NumberInput,
		SelectInput,
		CreateSoftwareType,
		RadioInputCustom,
	},

	methods: {
		getCmsType() {
			this.pageLoading = true;
			this.$store
				.dispatch(GET, {
					url: "setting/crm_type_active",
				})
				.then((data) => {
					this.cmsType = data;
				})
				.catch((error) => {
					this.logError(error);
					this.goBack();
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		changeMonthPeriod(invoice_type = 12) {
			invoice_type = parseInt(invoice_type);

			let newArrray = [];
			for (var k = invoice_type; k <= 24; k += invoice_type) {
				newArrray.push({ text: k, value: k });
			}
			this.invoice_months = newArrray;
			this.project.invoice_duration = 12;
			//console.log(newArrray);
		},

		filterServiceType(value = "") {
			if (value == "software") {
				this.project.checkbox_type = ["software"];
				this.hostingFuncyion("software");
				// this.$refs["customer_submit"].reset();
			} else if (value == "other") {
				this.project.checkbox_type = ["seo", "hosting", "ssl", "domain"];
				this.hostingFuncyion("seo");
				this.hostingFuncyion("hosting");
				// this.hostingFuncyion("web");
				// this.hostingFuncyion("maintenance");
				this.hostingFuncyion("ssl");
				this.hostingFuncyion("domain");

				this.dataTab = "seo";

				// this.$refs["customer_submit"].reset();
			} else {
				this.project.checkbox_type = [];
				//  this.hostingFuncyion("seo");

				// this.$refs["customer_submit"].reset();
			}
			this.hardReset();
		},
		//reAssignServer() {
		//console.count("reAssignServer:3076")
		//this.serverid = +new Date();
		//this.serverWeb = this.fixServerWeb;
		//console.log({ sw: this.serverWeb, fb: this.fixServerWeb })
		//},
		filterServers(serviceProvider = "") {
			let allServers = this.fixServerWeb;
			if (allServers.length) {
				allServers = filter(allServers, (row) => row.provider_type == "company");
			}
			if (!serviceProvider) {
				this.serverWeb = allServers;
			} else {
				this.serverWeb = filter(
					allServers,
					(row) => row.service_provider == serviceProvider //this.project.hosting[0].service_provider
				);
			}
		},
		hardReset() {
			this.project = {
				...this.project,
				hosting: [
					{
						cpanal_url: null,
						cost: 0,
						invoice_duration: "12",
						priod_month: 12,
						invoice_end_date: null,
						invoice_start_date: new Date().toISOString().substr(0, 10),
						duration: "12",
						remark: null,
						start_date: null,
						end_date: null,
						cms: null,
						cms_url: null,
						server: null,
						service_provider: null,
						type: "bthrust",
						related_type: 0,
						is_filld: false,
					},
				],
				ssl: [
					{
						domain_name: null,
						server: null,
						service_provider: null,
						cost: 0,
						invoice_duration: "12",
						priod_month: 12,
						invoice_end_date: null,
						invoice_start_date: new Date().toISOString().substr(0, 10),
						type: "bthrust",
						duration: "12",
						remark: null,
						related_type: 0,
						start_date: null,
						end_date: null,
						end_date_readonly: null,
						notification_person: [],
					},
				],
				web: [
					{
						domain: null,
						remark: null,
						duration: "12",
						cost: 0,
						invoice_duration: "12",
						priod_month: 12,
						invoice_end_date: null,
						invoice_start_date: new Date().toISOString().substr(0, 10),
						cms: null,
						cms_url: null,
						type: "bthrust",
						related_type: 0,
						start_date: null,
						end_date: null,
					},
				],
				domain: [
					{
						name: null,
						service_provider: null,
						cost: 0,
						invoice_duration: "12",
						priod_month: 12,
						invoice_end_date: null,
						invoice_start_date: new Date().toISOString().substr(0, 10),
						type: "bthrust",
						related_type: 0,
						start_date: null,
						end_date: null,
						remark: null,
						duration: "12",
						notification_person: [],
					},
				],
				maintenance: [
					{
						type: "bthrust",
						duration: "12",
						cost: 0,
						domain: null,
						invoice_duration: "12",
						priod_month: 12,
						invoice_end_date: null,
						invoice_start_date: new Date().toISOString().substr(0, 10),
						related_type: 0,
						start_date: null,
						end_date: null,
						remark: null,
					},
				],
				software: [
					{
						software_type: null,
						tags: null,
						sale_date: null,
						live_expected: null,
						live_actual: null,
						duration: "12",
						time_free: null,
						is_grant: false,

						service_provider: null,
						server: null,

						recurring_date: null,
						no_of_users: null,
						total_cost: null,
						cost_per_user: null,
						time_interval: "monthly",
						remark: null,

						project_manager_sg: null,
						primary_support: null,
						secondary_support: null,

						grant_type: "edg",
						filling_date: null,

						loa_date: null,
						system_live: null,
						claim_deadline: null,
					},
				],
				customer_services: [
					{
						id: 0,
						keyword_monthly: null,
						keyword_free: null,
						total_time: "12",
						project_manager: null,
						time_free: null,
						select_date: new Date().toISOString().substr(0, 10),
						total_time_end: null,
						time_free_end: null,
						cost: 0,
						invoice_duration: "12",
						priod_month: 12,
						invoice_end_date: null,
						invoice_start_date: new Date().toISOString().substr(0, 10),
						payment_terms: 0,
						remark: null,
						customer_urls: [
							{
								website_urls: null,
								url_target_google: 0,
								keyword_files: [
									{
										file: null,
										name: null,
									},
								],
							},
						],
					},
				],
			};
		},
		getServiceProvider(type) {
			return filter(this.serverProvider, (row) => row[type] == 1 && row.type == "company");
		},
		deleteCheck() {
			let checkValue;
			if (this.deleteSPType) {
				if (this.deleteSPType.includes("hosting")) {
					checkValue = this.project.hosting[0].service_provider;
				}
				if (this.deleteSPType.includes("ssl")) {
					let sep = this.deleteSPType.split(":");

					// let cc;
					// this.project.ssl.map((vv, idx) => {
					//  	let found = this.serverProvider.findIndex(v => {
					// 		v.value == vv.service_provider
					// 	})
					// 	if (found == -1) {
					// 		cc = idx;
					// 		return false;
					// 	}
					// })

					// console.log({ cc })

					checkValue = this.project.ssl[sep[1]].service_provider;
				}
				if (this.deleteSPType.includes("domain")) {
					let sep = this.deleteSPType.split(":");
					checkValue = this.project.domain[sep[1]].service_provider;
				}
				if (this.deleteSPType.includes("software")) {
					checkValue = this.project.software[0].service_provider;
				}

				let found = this.serverProvider.findIndex((v) => v.value == checkValue);

				if (checkValue && found == -1) {
					if (this.deleteSPType.includes("hosting")) {
						this.project.hosting[0].service_provider = null;
					}
					if (this.deleteSPType.includes("ssl")) {
						let sep = this.deleteSPType.split(":");
						this.project.ssl[sep[1]].service_provider = null;
					}
					if (this.deleteSPType.includes("domain")) {
						let sep = this.deleteSPType.split(":");
						this.project.domain[sep[1]].service_provider = null;
					}
					if (this.deleteSPType.includes("software")) {
						this.project.software[0].service_provider = null;
					}
				}
			}
		},
		deleteSoftTypeCheck() {
			let found = this.software_types.findIndex(
				(v) => v.value == this.project.software[0].software_type
			);
			if (found == -1) {
				this.project.software[0].software_type = null;
			}
		},
		cancleCreateProject() {
			let query = this.$route.query;
			if (query.project_renew) {
				delete query.project_renew;
			}

			let latestQuery = {
				...query,
				contract_id: null,
				convert_project: null,
				t: new Date().getTime(),
			};

			delete latestQuery.contract_id;
			delete latestQuery.convert_project;

			this.$router.replace({
				name: "project",
				query: latestQuery,
			});
		},
		calculateUsersAndCost(value, field) {
			let cost_per_user = this.project.software[0].cost_per_user
				? Number(this.project.software[0].cost_per_user) || 0
				: 0;
			let total_amount = this.project.software[0].total_cost
				? Number(this.project.software[0].total_cost) || 0
				: 0;
			let no_of_users = this.project.software[0].no_of_users
				? Number(this.project.software[0].no_of_users) || 0
				: 0;

			if (field == "users") {
				no_of_users = isBoolean(value) ? Number(value) || 0 : 0;
				let mm = total_amount / no_of_users;
				this.project.software[0].cost_per_user = isNaN(Number(mm)) ? 0 : Number(mm).toFixed(2);
			} else if (field == "total") {
				total_amount = isBoolean(value) ? Number(value) || 0 : 0;
				let mm = total_amount / no_of_users;
				this.project.software[0].cost_per_user = isNaN(Number(mm)) ? 0 : Number(mm).toFixed(2);
			} else if (field == "cost") {
				cost_per_user = isBoolean(value) ? Number(value) || 0 : 0;
				let mm = cost_per_user * no_of_users;
				this.project.software[0].total_cost = isNaN(Number(mm)) ? 0 : Number(mm).toFixed(2);
			}

			// let cost_per_user = objectPath.get(this.contract, "software.0.cost_per_user");
			// let total_amount = objectPath.get(this.project, "software.0.total_cost");
			// let no_of_users = objectPath.get(this.project, "software.0.no_of_users");

			// let users, totalAmount, costPerUser;
			// if (field == "users") {
			// 	users = +value;
			// 	if (total_amount) {
			// 		this.project.software[0].cost_per_user = total_amount / users;
			// 	}
			// } else if (field == "total") {
			// 	totalAmount = +value;
			// 	if (no_of_users) {
			// 		this.project.software[0].cost_per_user = totalAmount / no_of_users;
			// 	}
			// } else if (field == "cost") {
			// 	costPerUser = +value;
			// 	if (total_amount) {
			// 		this.project.software[0].no_of_users = Math.floor(total_amount / costPerUser);
			// 	}
			// }
		},
		/* uploadDialog() {
			this.openUploadModel = true;
		}, */
		hasKey(obj, key) {
			return objectPath.has(obj, key);
		},
		getValue(obj, key) {
			return objectPath.get(obj, key);
		},
		support_user_select(id, type) {
			if (type == "primary") {
				if (id) {
					let list = cloneDeep(this.secondary_support_users);
					let updated_list = cloneDeep(list.filter((v) => v.id != id));
					this.secondary_support_users = [...updated_list];
				} else {
					this.secondary_support_users = [...this.users];
				}
			} else if (type == "secondary") {
				if (id) {
					let list = cloneDeep(this.primary_support_users);
					let updated_list = cloneDeep(list.filter((v) => v.id != id));
					this.primary_support_users = [...updated_list];
				} else {
					this.primary_support_users = [...this.users];
				}
			}
		},
		onfocus() {
			this.$store.commit(SET_ERROR, this.errors.concat(["You can't edit readonly field."]));
		},
		domainFieldDisabled(index) {
			if (index < this.fieldsDisabled) {
				return true;
			} else {
				return false;
			}
		},
		debounceFunc(cb) {
			/* Assign domain to ssl and domain */
			clearTimeout(this.debounceTimer);
			this.debounceTimer = setTimeout(() => {
				cb();
			}, 300);
		},
		assignToDomain() {
			/* let new_domain = event.target.value; */
			let type = this.project.seo_type;

			let urls_arr;
			let unq_set;

			if (this.project.checkbox_type.includes("seo")) {
				/* filter(Boolean) to avoid saving null values */
				if (type == "single") {
					let urlArray = cloneDeep(this.project.customer_services);
					urls_arr = urlArray.map((urls) => urls.customer_urls[0].website_urls).filter(Boolean);
				} else if (type == "combined") {
					let urlArray = cloneDeep(this.project.customer_services[0].customer_urls);
					urls_arr = urlArray.map((urls) => urls.website_urls).filter(Boolean);
				}

				unq_set = [...new Set(urls_arr)];
				this.fieldsDisabled = unq_set.length;

				unq_set.forEach((url, idx) => {
					let d_data;
					let s_data;

					let dd_domain = cloneDeep(this.project.domain[idx]);

					if (dd_domain) {
						d_data = {
							...dd_domain,
							name: url,
						};
					} else {
						d_data = {
							name: url,
							cost: 0,
							invoice_duration: "12",
							priod_month: 12,
							invoice_end_date: null,
							invoice_start_date: new Date().toISOString().substr(0, 10),
							type: "bthrust",
							related_type: 0,
							start_date: null,
							end_date: null,
							remark: null,
							duration: "12",
							notification_person: [],
						};
					}

					let ss_ssl = cloneDeep(this.project.ssl[idx]);
					if (ss_ssl) {
						s_data = {
							...ss_ssl,
							domain_name: url,
						};
					} else {
						s_data = {
							domain_name: url,
							server: null,
							service_provider: null,
							cost: 0,
							invoice_duration: "12",
							priod_month: 12,
							invoice_end_date: null,
							invoice_start_date: new Date().toISOString().substr(0, 10),
							type: "bthrust",
							duration: "12",
							remark: null,
							related_type: 0,
							/* start_date: null,
											end_date: null, */
							notification_person: [],
						};
					}

					this.copySdata = s_data;
					this.copyDdata = d_data;

					this.project.domain[idx] = null;
					this.project.ssl[idx] = null;

					this.project.domain[idx] = this.copyDdata;
					this.project.ssl[idx] = this.copySdata;
				});

				if (this.project.domain.length > unq_set.length) {
					this.project.domain.pop(unq_set.length + 1);
				}

				if (this.project.ssl.length > unq_set.length) {
					this.project.ssl.pop(unq_set.length + 1);
				}
			}
		},
		urlInput(e) {
			if (
				this.project.customer_services.length > 1 ||
				this.project.customer_services[0].customer_urls.length > 1
			) {
				return false;
			} else {
				let d_found =
					this.project.checkbox_type.length && this.project.checkbox_type.includes("domain");
				let s_found = this.project.checkbox_type.length && this.project.checkbox_type.includes("ssl");

				if (!d_found) {
					this.project.checkbox_type.push("domain");
				}
				if (!s_found) {
					this.project.checkbox_type.push("ssl");
				}
				if (!e) {
					let domainIdx = this.project.checkbox_type.findIndex((v) => v == "domain");

					if (domainIdx > -1) {
						this.project.checkbox_type.splice(domainIdx, 1);
					}
					let sslIdx = this.project.checkbox_type.findIndex((v) => v == "ssl");
					if (sslIdx > -1) {
						this.project.checkbox_type.splice(sslIdx, 1);
					}
				}
			}
		},
		/* remove(item) {
			const index = this.project.user.indexOf(item.id);
			this.project.user.splice(index, 1);
		}, */
		otherTargetCountryActive(id) {
			let found = this.target_list.find((v) => v.id == id);
			return found ? (found.value == "other" ? true : false) : false;
		},
		dateFormated(data, index, soeType) {
			if (data.select_date && soeType == "seo-service") {
				let date = MomentJS(data.select_date, "YYYY-MM-DD");
				date.add(data.total_time, "months");
				// date.format("YYYY-MM-DD")
				this.project.customer_services[index].total_time_end = date
					.subtract(1, "days")
					.format("YYYY-MM-DD");

				if (data.time_free) {
					let add = toSafeInteger(data.time_free) + toSafeInteger(data.total_time);
					let date = MomentJS(data.select_date, "YYYY-MM-DD");
					date.add(add, "months");
					this.project.customer_services[index].time_free_end = date.format("YYYY-MM-DD");
				}

				this.disableDates.seo[index] = this.calculate_next_day(
					this.project.customer_services[index].select_date
				);
				this.disableDates.seo_free[index] = this.calculate_next_day(
					this.project.customer_services[index].total_time_end
				);
			}
			console.log("clear date", data.select_date, soeType);
			if (!data.select_date && soeType == "seo-service") {
				this.project.customer_services[index].time_free_end = null;
				this.project.customer_services[index].total_time_end = null;
			}

			if (data.live_actual && soeType == "software") {
				let date = MomentJS(data.live_actual, "YYYY-MM-DD");
				let months = +data.duration + (data.time_free ? +data.time_free : 0);
				date.add(months, "months");
				this.project.software[index].recurring_date = date.subtract(1, "days").format("YYYY-MM-DD");
			}

			if (data.start_date && index == "hosting") {
				let date = MomentJS(data.start_date, "YYYY-MM-DD");
				date.add(data.duration, "months");
				date.subtract(1, "days");
				this.project.hosting[0].end_date = date.format("YYYY-MM-DD");
			}

			if (data.start_date && soeType == "ssl") {
				let date = MomentJS(data.start_date, "YYYY-MM-DD");
				date.add(data.duration, "months");
				date.subtract(1, "days");
				let idx = toSafeInteger(index);
				this.project.ssl[idx].end_date = date.format("YYYY-MM-DD");
				this.project.ssl[idx].end_date_readonly = date.format("YYYY-MM-DD");
				this.disableDates.ssl[idx] = this.calculate_next_day(this.project.ssl[index].start_date);
			}

			if (data.start_date && index == "web") {
				let date = MomentJS(data.start_date, "YYYY-MM-DD");
				date.add(data.duration, "months");
				date.subtract(1, "days");
				this.project.web[0].end_date = date.format("YYYY-MM-DD");

				this.disableDates.web[0] = this.calculate_next_day(this.project.web[0].start_date);
			}

			if (data.start_date && soeType == "domain") {
				let date = MomentJS(data.start_date, "YYYY-MM-DD");
				date.add(data.duration, "months");
				// this.project.domain[0].end_date = date.format("YYYY-MM-DD");
				/* To disable past from this date in end date */
				this.disableDates.domain[index] = this.calculate_next_day(
					this.project.domain[index].start_date
				);

				let ends_on = date.subtract(1, "days").format("YYYY-MM-DD");

				this.project.domain[index].end_date = ends_on;
			}

			if (data.start_date && index == "maintenance") {
				let date = MomentJS(data.start_date, "YYYY-MM-DD");
				date.add(data.duration, "months");
				date.subtract(1, "days");
				this.project.maintenance[0].end_date = date.format("YYYY-MM-DD");
			}

			if (data && data.invoice_start_date && index == "invoice") {
				this.maintainDates(this.project.billing_type);
				this.disablePastDate = this.calculate_next_day(this.project.invoice_start_date);
				if (this.project.billing_type == "single_billing") {
					let date = MomentJS(data.invoice_start_date, "YYYY-MM-DD");
					let countPeriod = data.invoice_duration;
					date.add(countPeriod, "months");
					this.project.invoice_end_date = date.subtract(1, "days").format("YYYY-MM-DD");
					this.project.total_invoice = data.invoice_duration / data.priod_month;
				}
			}
			//console.log(this.project.ssl[index].end_date);
		},
		limitDecimal($event, value) {
			let string = toString(value);

			// console.log($event.keyCode); //keyCodes string
			let keyCode = $event.keyCode ? $event.keyCode : $event.which;

			// only allow number and one dot
			if (
				((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || string.indexOf(".") != -1)) ||
				string.length > 11
			) {
				// 46 is dot
				$event.preventDefault();
			}

			// restrict to 2 decimal places
			if (string != null && string.indexOf(".") > -1 && string.split(".")[1].length > 1) {
				$event.preventDefault();
			}
		},
		getContractAttendie(id) {
			this.$store
				.dispatch(GET, {
					url: `single-contract/${id}`,
				})
				.then((data) => {
					this.salesPserson = data.attendie[0].attendies.length ? data.attendie[0].attendies : [];
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getproject() {
			this.checkData = [];
			this.$store
				.dispatch(GET, {
					url: `single-project/${this.projectId ? this.projectId : this.project_renew}`,
				})
				.then((data) => {
					//console.log(data);
					this.getCustomerSalesPeson(data.contract.customer);
					this.checkData = data;
					this.project.barcode = data.project.barcode;
					this.project.cost = data.project.cost;

					this.project.contract = data.project.contract;
					this.project.billing_type = data.project.billing_type;
					this.project.type = data.project.type;
					this.project.customer = data.project.customer;
					this.project.seo_type = data.project.seo_type;
					this.project.checkbox_type = data.project.checkbox_type.split(",");
					this.project.project_month = data.project.project_month;
					this.project.project_start_date = data.project.project_start_date;
					this.project.project_end_date = data.project.project_end_date;
					this.project.sales_date = data.project.sales_date;
					this.project.invoice_date = data.project.invoice_date;
					this.project.priod_month = toSafeInteger(data.project.priod_month);
					this.project.invoice_start_date = data.project.invoice_start_date;
					this.project.invoice_end_date = data.project.invoice_end_date;

					this.project.service_option = data.project.service_option;
					// Below Tabs data fill during renew the project
					this.project.customer_services = data.customer_services_renewal; //data.customer_services;

					let _new_array = [];

					//modify array for combined seo
					if (this.project.seo_type == "combined" && this.project.customer_services.length > 0) {
						let _new_data = cloneDeep(data.customer_services_renewal);
						const _urls_list = _new_data.map((row) => {
							row.customer_urls[0].seo_renewal_id = row.id;
							return row.customer_urls[0];
						});

						// _new_data[0].customer_urls = _urls_list;
						_new_array.push(_new_data[0]);
						_new_array[0].customer_urls = _urls_list;

						this.project.customer_services = _new_array;
					}

					this.project.hosting = data.customer_hosting.length
						? data.customer_hosting
						: this.project.hosting;

					this.project.ssl = data.customer_ssl;
					this.project.domain = data.domain;

					this.project.web = data.customer_web.length ? data.customer_web : this.project.web;
					//console.log(this.project.maintenance);
					this.project.maintenance = data.maintenance.length
						? data.maintenance
						: this.project.maintenance;
					//console.log(this.project.maintenance);
					this.project.software = data.software;

					if (this.project_renew && this.project_renew > 0) {
						/* Disabled previous dates in Invoice Start date during project renew to avoid inconsistency */
						let end_date_of_project = objectPath.get(data, "invoice_data.priod_end_date");

						if (end_date_of_project) {
							let date = MomentJS(end_date_of_project, "YYYY-MM-DD");

							if (
								new Date().getTime() > new Date(end_date_of_project).getTime() ||
								new Date().getTime() == new Date(end_date_of_project).getTime()
							) {
								this.project.invoice_start_date = MomentJS(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD");
							} else {
								this.project.invoice_start_date = date.add(1, "day").format("YYYY-MM-DD");
							}
							this.restrictStartDateWhenRenew = date.format("YYYY-MM-DD");
						}

						//make ssl domains remove btn disable if seo has domains
						if (this.project.customer_services.length) {
							this.fieldsDisabled = this.project.customer_services.length;
						}

						//set sales persons of project on renew
						/* this.salesPserson = data.attendie[0].attendies.length ? data.attendie[0].attendies : []; */
						let contract_id = toSafeInteger(objectPath.get(data, "contract.id"));
						if (contract_id > 0) {
							this.getContractAttendie(contract_id);
						}
					}

					// let foundSalesPerson = this.salesPserson.findIndex(sp => sp.id == data.project.user)
					// if (foundSalesPerson > -1) {
					this.project.user = data.project.user;
					// } else {
					// 	this.project.user = null;
					// }

					if (this.project.customer_services.length) {
						this.project.customer_services = data.customer_services_renewal; //data.customer_services;
						if (this.project.seo_type == "combined" && _new_array.length > 0) {
							this.project.customer_services = _new_array;
						}

						if (this.project.customer_services[0].customer_urls.length > 0) {
							this.project.customer_services[0].customer_urls[0].keyword_files = [
								{
									customer_urls: [
										{
											keyword_files: [
												{
													file: null,
													name: null,
												},
											],
										},
									],
								},
							];
						} else {
							this.project.customer_services[0].customer_urls = [
								{
									website_urls: null,
									url_target_google: null,
									keyword_files: [
										{
											file: null,
											name: null,
										},
									],
								},
							];
						}
					} else {
						this.project.customer_services = [
							{
								keyword_monthly: null,
								keyword_free: null,
								total_time: "12",
								time_free: null,
								select_date: null,
								total_time_end: null,
								time_free_end: null,
								amount: null,
								payment_terms: 0,
								remark: null,
								customer_urls: [
									{
										website_urls: null,
										url_target_google: null,
										keyword_files: [
											{
												file: null,
												name: null,
											},
										],
									},
								],
							},
						];
					}

					if (data.maintenance.length) {
						this.project.maintenance = data.maintenance;
						if (data?.maintenance[0]) {
							this.project.maintenance[0].duration = data.maintenance[0].duration
								? data.maintenance[0].duration
								: "12"; // "24";
						} else {
							this.project.maintenance[0].duration = "12"; // "24";
						}
					} else {
						this.project.maintenance = [
							{
								type: "bthrust",
								related_type: 0,
								customer: 0,
								start_date: null,
								duration: "12",
								end_date: null,
								cost: 0,
								remark: null,
							},
						];
					}

					if (data.domain.length) {
						let domain = cloneDeep(this.getValue(this.project, "domain"));
						domain.forEach((pp, index) => {
							this.project.domain[index].duration = pp.duration ? pp.duration : "12"; //"12";
							let notification_person = JSON.parse(this.project.domain[index].notification_person);
							this.project.domain[index].notification_person = Array.isArray(notification_person)
								? notification_person.map((v) => +v)
								: [];
						});
					} else {
						this.project.domain = [
							{
								name: null,
								duration: "12",
								remark: null,
								type: "bthrust",
								related_type: 0,
								customer: 0,
								start_date: null,
								end_date: null,
							},
						];
					}
					if (data.customer_ssl.length) {
						let ssl = cloneDeep(this.getValue(this.project, "ssl"));
						ssl.map((pp, index) => {
							//console.log(pp.duration);
							this.project.ssl[index].duration = pp.duration ? pp.duration : "12"; //"12"; //ssl[index].duration;
							let notification_person = JSON.parse(this.project.ssl[index].notification_person);
							this.project.ssl[index].notification_person = Array.isArray(notification_person)
								? notification_person.map((v) => +v)
								: [];
						});
					} else {
						this.project.ssl = [
							{
								server: null,
								service_provider: null,
								type: "bthrust",
								related_type: 0,
								customer: 0,
								start_date: null,
								end_date: null,
								duration: "12",
								remark: null,
								cost: 0,
							},
						];
					}

					if (this.project.software.length) {
						/* this.project.software[0].time_interval =
							data.software[0].time_interval == 1 ? "monthly" : "yearly"; */

						this.project.software[0].duration =
							(typeof data.software[0].duration == "number"
								? data.software[0].duration.toString()
								: data.software[0].duration) || "12";

						// this.support_user_select(this.project.software[0].primary_support, "primary");
						// this.support_user_select(this.project.software[0].secondary_support, "secondary");

						let tags = objectPath.get(data, "software.0.tags");

						let _tags = [];
						if (tags && Array.isArray(tags) && tags.length) {
							data.software[0].tags.map((v) => {
								_tags.push({ ...v, text: v.tag });
							});
						}

						this.project.software[0].tags = _tags;

						this.project.software[0].project_manager_sg = objectPath.get(
							data,
							"software.0.project_manager_sg"
						)
							? +data.software[0].project_manager_sg.id
							: 0;

						this.project.software[0].primary_support = objectPath.get(data, "software.0.primary_support")
							? +data.software[0].primary_support.id
							: 0;

						this.project.software[0].secondary_support = objectPath.get(
							data,
							"software.0.secondary_support"
						)
							? +data.software[0].secondary_support.id
							: 0;

						this.project.software[0].is_grant = Boolean(data.software[0].is_grant);

						this.project.software[0].grant_type = this.project.software[0].is_grant
							? this.project.software[0].grant_type
							: "edg";
					} else {
						this.project.software = [
							{
								software_type: null,
								tags: null,
								sale_date: null,
								live_expected: null,
								live_actual: null,
								duration: "12",
								time_free: null,
								is_grant: false,

								service_provider: null,
								server: null,

								recurring_date: null,
								no_of_users: null,
								total_cost: null,
								cost_per_user: null,
								time_interval: "monthly",
								remark: null,

								project_manager_sg: null,
								primary_support: null,
								secondary_support: null,

								grant_type: "edg",
								filling_date: null,

								loa_date: null,
								system_live: null,
								claim_deadline: null,
							},
						];
					}
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		hostingFuncyion(value) {
			console.log(`hostingFuncyion(${value})`);

			this.dataTab = value;

			let purpose =
				this.project.checkbox_type.length && this.project.checkbox_type.includes(value)
					? "add"
					: "purge";

			switch (value) {
				case "ssl":
					if (
						this.project.checkbox_type.find((v) => v == "seo") &&
						!this.project.checkbox_type.find((v) => v == "ssl")
					) {
						this.project.checkbox_type.push("ssl");
					}
					this.project.ssl[0].type = "bthrust";
					break;
				case "hosting":
					this.project.hosting[0].type = "bthrust";
					break;
				case "web":
					this.project.web[0].type = "bthrust";
					break;
				case "domain":
					if (
						this.project.checkbox_type.find((v) => v == "seo") &&
						!this.project.checkbox_type.find((v) => v == "domain")
					) {
						this.project.checkbox_type.push("domain");
					}
					this.project.domain[0].type = "bthrust";

					if (
						this.project.customer_services &&
						this.project.customer_services.length &&
						this.project.customer_services[0].customer_urls.length > 0
					) {
						let unq_arr_urls = this.project.domain.length;

						console.log({ purpose, unq_arr_urls });

						if (purpose == "add") {
							this.assignToDomain();
						} else if (purpose == "purge") {
							for (let i = unq_arr_urls - 1; i > 0; i--) {
								this.removeDomainField(i);
							}
						}
					}
					break;
				case "maintenance":
					this.project.maintenance[0].type = "bthrust";
					break;
				// case "software":
				// 	this.project.software[0].type = "bthrust";
				// break;
				case "seo":
					if (!this.project.checkbox_type.find((v) => v == "ssl")) {
						this.project.checkbox_type.push("ssl");
					}
					if (!this.project.checkbox_type.find((v) => v == "domain")) {
						this.project.checkbox_type.push("domain");
					}
					this.project.checkbox_type;
					this.project.seo_type = "single";
					break;
			}
		},
		deleteServices(id, index) {
			if (id > 0) {
				this.$store
					.dispatch(DELETE, {
						url: `customer-service/${id}`,
					})
					.then(() => {
						this.$store.commit(SET_MESSAGE, [
							{
								model: true,
								message: "Success ! Customer service deleted successfully.",
							},
						]);
						this.getSettings();
					})
					.catch((error) => {
						console.log({
							error,
						});
					});
			} else {
				this.project.customer_services.splice(index, 1);
			}
		},
		createOrUpdateProject() {
			const formErrors = this.validateForm(this.$refs["customer_submit"]);

			this.$refs["customer_submit"].validate();

			if (formErrors.length) {
				this.$store.commit(SET_ERROR, this.errors.concat(formErrors));
				return false;
			}

			if (!this.$refs["customer_submit"].validate()) {
				return false;
			}
			if (this.project.checkbox_type.length == 0) {
				this.$store.commit(SET_ERROR, [
					{ model: true, message: "Atleast one service type is required." },
					/* this.leaveCreate = {} */
				]);
				return false;
			}
			this.pageLoading = true;

			let formData = new FormData();

			formData.append("cost", this.project.cost ? this.project.cost : 0);
			formData.append("convert_project", this.convert_project ? this.convert_project : false);
			formData.append("billing_type", this.project.billing_type ? this.project.billing_type : null);
			formData.append(
				"invoice_duration",
				this.project.invoice_duration ? this.project.invoice_duration : "12"
			);
			if (this.project && this.project.checkbox_type) {
				formData.append(
					"checkbox_type",
					this.project.checkbox_type ? this.project.checkbox_type : null
				);
			}

			if (this.project_renew && this.project_renew > 0) {
				formData.append("projectId", this.project_renew ? this.project_renew : 0);
			}

			if (this.project_renew && this.project_renew > 0) {
				formData.append("renewType", "renew");
			}

			if (this.project && this.project.invoice_start_date) {
				formData.append(
					"invoice_start_date",
					this.project.invoice_start_date ? this.project.invoice_start_date : null
				);
			}
			if (this.project && this.project.invoice_end_date) {
				formData.append(
					"invoice_end_date",
					this.project.invoice_end_date ? this.project.invoice_end_date : null
				);
			}
			formData.append(
				"service_option",
				this.project.service_option ? this.project.service_option : null
			);
			if (this.project && this.project.priod_month) {
				formData.append("priod_month", this.project.priod_month ? this.project.priod_month : null);
			}
			formData.append("contract", this.project.contract ? this.project.contract : 0);
			formData.append("customer", this.customerId ? this.customerId : this.project.customer);
			formData.append("user", this.project.user ? this.project.user : 0);
			formData.append("seo_type", this.project.seo_type ? this.project.seo_type : null);
			formData.append("entity_type", this.project.entity_type);

			const brand = some(this.project.checkbox_type, (row) => row == "hosting");
			this.checkpayload = brand;
			if (this.checkpayload == true) {
				if (objectPath.get(this.project, `hosting.0.cpanal_url`)) {
					formData.append("hosting[0][cpanal_url]", this.project.hosting[0].cpanal_url);
				}
				if (objectPath.get(this.project, `hosting.0.duration`)) {
					formData.append("hosting[0][duration]", this.project.hosting[0].duration);
				}
				if (objectPath.get(this.project, `hosting.0.start_date`)) {
					formData.append("hosting[0][start_date]", this.project.hosting[0].start_date);
				}
				// if (objectPath.get(this.project, `hosting.0.company_type`)) {
				// 	formData.append("hosting[0][company_type]", this.project.hosting[0].company_type);
				// }
				if (objectPath.get(this.project, `hosting.0.end_date`)) {
					formData.append("hosting[0][end_date]", this.project.hosting[0].end_date);
				}
				if (objectPath.get(this.project, `hosting.0.domain`)) {
					formData.append("hosting[0][domain]", this.project.hosting[0].domain);
				}
				if (objectPath.get(this.project, `hosting.0.cms`)) {
					formData.append("hosting[0][cms]", this.project.hosting[0].cms);
				}
				if (objectPath.get(this.project, `hosting.0.cost`)) {
					formData.append("hosting[0][cost]", this.project.hosting[0].cost);
				}
				if (objectPath.get(this.project, `hosting.0.remark`)) {
					formData.append("hosting[0][remark]", this.project.hosting[0].remark);
				}

				if (objectPath.get(this.project, `hosting.0.cms_url`)) {
					formData.append("hosting[0][cms_url]", this.project.hosting[0].cms_url);
				}
				if (objectPath.get(this.project, `hosting.0.server`)) {
					formData.append("hosting[0][server]", this.project.hosting[0].server);
				}
				if (objectPath.get(this.project, `hosting.0.service_provider`)) {
					formData.append("hosting[0][service_provider]", this.project.hosting[0].service_provider);
				}
				if (objectPath.get(this.project, `hosting.0`)) {
					formData.append("hosting[0][type]", "bthrust");
				}
				formData.append(
					"hosting[0][invoice_duration]",
					this.project.hosting[0].invoice_duration ? this.project.hosting[0].invoice_duration : "12"
				);

				if (objectPath.get(this.project, `hosting.0.invoice_start_date`)) {
					formData.append(
						"hosting[0][invoice_start_date]",
						this.project.hosting[0].invoice_start_date ? this.project.hosting[0].invoice_start_date : null
					);
				}
				if (objectPath.get(this.project, `hosting.0.invoice_end_date`)) {
					formData.append(
						"hosting[0][invoice_end_date]",
						this.project.hosting[0].invoice_end_date ? this.project.hosting[0].invoice_end_date : null
					);
				}
				if (objectPath.get(this.project, `hosting.0.priod_month`)) {
					formData.append(
						"hosting[0][priod_month]",
						this.project.hosting[0].priod_month ? this.project.hosting[0].priod_month : 0
					);
				}
				if (objectPath.get(this.project, `hosting.0.cost`)) {
					formData.append(
						"hosting[0][cost]",
						this.project.hosting[0].cost ? this.project.hosting[0].cost : 0
					);
				}
			}
			const ssl = some(this.project.checkbox_type, (row) => row == "ssl");
			this.checkpayload = ssl;
			if (this.checkpayload == true) {
				if (objectPath.get(this.project, `ssl`).length) {
					for (let s = 0; s < this.project.ssl.length; s++) {
						if (objectPath.get(this.project, `ssl.${s}.service_provider`)) {
							formData.append(`ssl[${s}][service_provider]`, this.project.ssl[s].service_provider);
						}
						if (objectPath.get(this.project, `ssl.${s}`)) {
							formData.append(`ssl[${s}][type]`, "bthrust");
						}
						if (objectPath.get(this.project, `ssl.${s}.start_date`)) {
							formData.append(`ssl[${s}][start_date]`, this.project.ssl[s].start_date);
						}
						// if (objectPath.get(this.project, `ssl.0.company_type`)) {
						// 	formData.append("ssl[0][company_type]", this.project.ssl[0].company_type);
						// }
						if (objectPath.get(this.project, `ssl.${s}.end_date`)) {
							formData.append(`ssl[${s}][end_date]`, this.project.ssl[s].end_date);
						}
						if (objectPath.get(this.project, `ssl.0.cost`)) {
							formData.append(`ssl[${s}][cost]`, this.project.ssl[0].cost ? this.project.ssl[0].cost : 0);
						}
						if (objectPath.get(this.project, `ssl.${s}.duration`)) {
							formData.append(`ssl[${s}][duration]`, this.project.ssl[s].duration);
						}
						if (objectPath.get(this.project, `ssl.${s}.remark`)) {
							formData.append(`ssl[${s}][remark]`, this.project.ssl[s].remark);
						}
						formData.append(
							`ssl[${s}][invoice_duration]`,
							this.project.ssl[0].invoice_duration ? this.project.ssl[0].invoice_duration : "12"
						);

						if (objectPath.get(this.project, `ssl.0.invoice_start_date`)) {
							formData.append(
								`ssl[${s}][invoice_start_date]`,
								this.project.ssl[0].invoice_start_date ? this.project.ssl[0].invoice_start_date : null
							);
						}
						if (objectPath.get(this.project, `ssl.0.invoice_end_date`)) {
							formData.append(
								`ssl[${s}][invoice_end_date]`,
								this.project.ssl[0].invoice_end_date ? this.project.ssl[0].invoice_end_date : null
							);
						}
						if (objectPath.get(this.project, `ssl.0.priod_month`)) {
							formData.append(
								`ssl[${s}][priod_month]`,
								this.project.ssl[0].priod_month ? this.project.ssl[0].priod_month : 0
							);
						}

						if (objectPath.get(this.project, `ssl.${s}.domain_name`)) {
							formData.append(
								`ssl[${s}][domain_name]`,
								this.project.ssl[s].domain_name ? this.project.ssl[s].domain_name : null
							);
						}

						if (
							objectPath.get(this.project, `ssl.${s}.notification_person`) &&
							objectPath.get(this.project, `ssl.${s}.notification_person`).length > 0
						) {
							this.project.ssl[s].notification_person.forEach((n_p, index) => {
								formData.append(`ssl[${s}][notification_person][${index}]`, n_p ? n_p : null);
							});
						}
					}
				}
			}
			const web = some(this.project.checkbox_type, (row) => row == "web");
			this.checkpayload = web;
			if (this.checkpayload == true) {
				/* if(this.project && this.project.web && this.project.web[0] && this.project.web[0].server_url){
					formData.append("web[0][server_url]", this.project.web[0].server_url);
				} */
				if (objectPath.get(this.project, `web.0.remark`)) {
					formData.append("web[0][remark]", this.project.web[0].remark);
				}
				if (objectPath.get(this.project, `web.0.cms`)) {
					formData.append("web[0][cms]", this.project.web[0].cms);
				}
				// if (objectPath.get(this.project, `web.0.company_type`)) {
				// 	formData.append("web[0][company_type]", this.project.web[0].company_type);
				// }
				if (objectPath.get(this.project, `web.0.cms_url`)) {
					formData.append("web[0][cms_url]", this.project.web[0].cms_url);
				}
				if (objectPath.get(this.project, `web.0.duration`)) {
					formData.append("web[0][duration]", this.project.web[0].duration);
				}
				if (objectPath.get(this.project, `web.0.domain`)) {
					formData.append("web[0][domain]", this.project.web[0].domain);
				}
				if (objectPath.get(this.project, `web.0.cost`)) {
					formData.append("web[0][cost]", this.project.web[0].cost);
				}
				if (objectPath.get(this.project, `web.0`)) {
					formData.append("web[0][type]", "bthrust");
				}
				if (objectPath.get(this.project, `web.0.start_date`)) {
					formData.append("web[0][start_date]", this.project.web[0].start_date);
				}
				if (objectPath.get(this.project, `web.0.end_date`)) {
					formData.append("web[0][end_date]", this.project.web[0].end_date);
				}
				formData.append(
					"web[0][invoice_duration]",
					this.project.web[0].invoice_duration ? this.project.web[0].invoice_duration : "12"
				);

				if (objectPath.get(this.project, `web.0.invoice_start_date`)) {
					formData.append(
						"web[0][invoice_start_date]",
						this.project.web[0].invoice_start_date ? this.project.web[0].invoice_start_date : null
					);
				}
				if (objectPath.get(this.project, `web.0.invoice_end_date`)) {
					formData.append(
						"web[0][invoice_end_date]",
						this.project.web[0].invoice_end_date ? this.project.web[0].invoice_end_date : null
					);
				}
				if (objectPath.get(this.project, `web.0.priod_month`)) {
					formData.append(
						"web[0][priod_month]",
						this.project.web[0].priod_month ? this.project.web[0].priod_month : 0
					);
				}
				if (objectPath.get(this.project, `web.0.cost`)) {
					formData.append("web[0][cost]", this.project.web[0].cost ? this.project.web[0].cost : 0);
				}
			}

			const domain = some(this.project.checkbox_type, (row) => row == "domain");
			this.checkpayload = domain;
			if (this.checkpayload == true) {
				for (let d = 0; d < objectPath.get(this.project, `domain`).length; d++) {
					if (objectPath.get(this.project, `domain.${d}.name`)) {
						formData.append(`domain[${d}][name]`, this.project.domain[d].name);
					}

					if (objectPath.get(this.project, `domain.${d}.service_provider`)) {
						formData.append(`domain[${d}][service_provider]`, this.project.domain[d].service_provider);
					}
					// if (objectPath.get(this.project, `domain.0.cost`))
					if (objectPath.get(this.project, `domain.${d}.duration`)) {
						formData.append(`domain[${d}][duration]`, this.project.domain[d].duration);
					}
					// if (objectPath.get(this.project, `domain.0.duration`)) {
					// 	formData.append("domain[d][duration]", this.project.domain[d].duration);
					// }
					// if (objectPath.get(this.project, `domain.0.company_type`)) {
					// 	formData.append("domain[0][company_type]", this.project.domain[0].company_type);
					// }
					if (objectPath.get(this.project, `domain.${d}.remark`)) {
						formData.append(`domain[${d}][remark]`, this.project.domain[d].remark);
					}

					if (objectPath.get(this.project, `domain.${d}.`)) {
						formData.append(`domain[${d}][type]`, "bthrust");
					}
					if (objectPath.get(this.project, `domain.${d}.start_date`)) {
						formData.append(`domain[${d}][start_date]`, this.project.domain[d].start_date);
					}
					if (objectPath.get(this.project, `domain.${d}.end_date`)) {
						formData.append(`domain[${d}][end_date]`, this.project.domain[d].end_date);
					}
					formData.append(
						`domain[${d}][invoice_duration]`,
						this.project.domain[0].invoice_duration ? this.project.domain[0].invoice_duration : "12"
					);

					if (objectPath.get(this.project, `domain.0.invoice_start_date`)) {
						formData.append(
							`domain[${d}][invoice_start_date]`,
							this.project.domain[0].invoice_start_date ? this.project.domain[0].invoice_start_date : null
						);
					}
					if (objectPath.get(this.project, `domain.0.invoice_end_date`)) {
						formData.append(
							`domain[${d}][invoice_end_date]`,
							this.project.domain[0].invoice_end_date ? this.project.domain[0].invoice_end_date : null
						);
					}
					if (objectPath.get(this.project, `domain.0.priod_month`)) {
						formData.append(
							`domain[${d}][priod_month]`,
							this.project.domain[0].priod_month ? this.project.domain[0].priod_month : 0
						);
					}
					if (objectPath.get(this.project, `domain.0.cost`)) {
						formData.append(
							`domain[${d}][cost]`,
							this.project.domain[0].cost ? this.project.domain[0].cost : 0
						);
					}
					if (objectPath.get(this.project, `domain.${d}.notification_person`)) {
						this.project.domain[d].notification_person.forEach((n_p, index) => {
							formData.append(`domain[${d}][notification_person][${index}]`, n_p ? n_p : null);
						});
					}
				}
			}

			const maintenance = some(this.project.checkbox_type, (row) => row == "maintenance");
			this.checkpayload = maintenance;
			if (this.checkpayload == true) {
				if (objectPath.get(this.project, `maintenance.0`)) {
					formData.append("maintenance[0][type]", "bthrust");
				}
				if (objectPath.get(this.project, `maintenance.0.duration`)) {
					formData.append("maintenance[0][duration]", this.project.maintenance[0].duration);
				}
				if (objectPath.get(this.project, `maintenance.0.start_date`)) {
					formData.append("maintenance[0][start_date]", this.project.maintenance[0].start_date);
				}
				if (objectPath.get(this.project, `maintenance.0.domain`)) {
					formData.append("maintenance[0][domain]", this.project.maintenance[0].domain);
				}
				// if (objectPath.get(this.project, `maintenance.0.company_type`)) {
				// 	formData.append("maintenance[0][company_type]", this.project.maintenance[0].company_type);
				// }
				if (objectPath.get(this.project, `maintenance.0.end_date`)) {
					formData.append("maintenance[0][end_date]", this.project.maintenance[0].end_date);
				}
				/* if (
					this.project &&
					this.project.maintenance &&
					this.project.maintenance[0] &&
					this.project.maintenance[0].cost
				) {
					formData.append("maintenance[0][cost]", this.project.maintenance[0].cost);
				} */
				if (objectPath.get(this.project, `maintenance.0.remark`)) {
					formData.append("maintenance[0][remark]", this.project.maintenance[0].remark);
				}
				formData.append(
					"maintenance[0][invoice_duration]",
					this.project.maintenance[0].invoice_duration
						? this.project.maintenance[0].invoice_duration
						: "12"
				);

				if (objectPath.get(this.project, `maintenance.0.invoice_start_date`)) {
					formData.append(
						"maintenance[0][invoice_start_date]",
						this.project.maintenance[0].invoice_start_date
							? this.project.maintenance[0].invoice_start_date
							: null
					);
				}
				if (objectPath.get(this.project, `maintenance.0.invoice_end_date`)) {
					formData.append(
						"maintenance[0][invoice_end_date]",
						this.project.maintenance[0].invoice_end_date
							? this.project.maintenance[0].invoice_end_date
							: null
					);
				}
				if (objectPath.get(this.project, `maintenance.0.priod_month`)) {
					formData.append(
						"maintenance[0][priod_month]",
						this.project.maintenance[0].priod_month ? this.project.maintenance[0].priod_month : 0
					);
				}
				if (objectPath.get(this.project, `maintenance.0.cost`)) {
					formData.append(
						"maintenance[0][cost]",
						this.project.maintenance[0].cost ? this.project.maintenance[0].cost : 0
					);
				}
			}

			const seo = some(this.project.checkbox_type, (row) => row == "seo");
			this.checkpayload = seo;
			if (this.checkpayload == true) {
				for (let i = 0; i < this.project.customer_services.length; i++) {
					if (objectPath.get(this.project, `customer_services.${i}.cost`)) {
						formData.append(`customer_services[${i}][cost]`, this.project.customer_services[i].cost);
					}
					if (objectPath.get(this.project, `customer_services.${i}.priod_month`)) {
						formData.append(
							`customer_services[${i}][priod_month]`,
							this.project.customer_services[i].priod_month
						);
					}
					if (objectPath.get(this.project, `customer_services.${i}.invoice_start_date`)) {
						formData.append(
							`customer_services[${i}][invoice_start_date]`,
							this.project.customer_services[i].invoice_start_date
						);
					}
					if (objectPath.get(this.project, `customer_services.${i}.invoice_end_date`)) {
						formData.append(
							`customer_services[${i}][invoice_end_date]`,
							this.project.customer_services[i].invoice_end_date
						);
					}
					if (objectPath.get(this.project, `customer_services.${i}.invoice_duration`)) {
						formData.append(
							`customer_services[${i}][invoice_duration]`,
							this.project.customer_services[i].invoice_duration
						);
					}

					if (objectPath.get(this.project, `customer_services.${i}.id`)) {
						formData.append(`customer_services[${i}][id]`, this.project.customer_services[i].id);
					}
					if (objectPath.get(this.project, `customer_services.${i}.keyword_monthly`)) {
						formData.append(
							`customer_services[${i}][keyword_monthly]`,
							this.project.customer_services[i].keyword_monthly
						);
					}
					if (objectPath.get(this.project, `customer_services.${i}.keyword_free`)) {
						formData.append(
							`customer_services[${i}][keyword_free]`,
							this.project.customer_services[i].keyword_free
						);
					}
					if (objectPath.get(this.project, `customer_services.${i}.total_time`)) {
						formData.append(
							`customer_services[${i}][total_time]`,
							this.project.customer_services[i].total_time
						);
					}
					if (objectPath.get(this.project, `customer_services.${i}.time_free`)) {
						formData.append(
							`customer_services[${i}][time_free]`,
							this.project.customer_services[i].time_free
						);
					}
					if (objectPath.get(this.project, `customer_services.${i}.select_date`)) {
						formData.append(
							`customer_services[${i}][select_date]`,
							this.project.customer_services[i].select_date
						);
					}
					if (objectPath.get(this.project, `customer_services.${i}.total_time_end`)) {
						formData.append(
							`customer_services[${i}][total_time_end]`,
							this.project.customer_services[i].total_time_end
						);
					}
					if (objectPath.get(this.project, `customer_services.${i}.time_free_end`)) {
						formData.append(
							`customer_services[${i}][time_free_end]`,
							this.project.customer_services[i].time_free_end
						);
					}
					if (objectPath.get(this.project, `customer_services.${i}.amount`)) {
						formData.append(`customer_services[${i}][amount]`, this.project.customer_services[i].amount);
					}
					if (objectPath.get(this.project, `customer_services.${i}.payment_terms`)) {
						if (this.project.customer_services[i].payment_terms.id) {
							formData.append(
								`customer_services[${i}][payment_terms]`,
								this.project.customer_services[i].payment_terms.id
							);
						} else {
							formData.append(
								`customer_services[${i}][payment_terms]`,
								this.project.customer_services[i].payment_terms
							);
						}
					}
					if (objectPath.get(this.project, `customer_services.${i}.remark`)) {
						formData.append(`customer_services[${i}][remark]`, this.project.customer_services[i].remark);
					}

					if (objectPath.get(this.project, `customer_services.${i}.project_manager`)) {
						formData.append(
							`customer_services[${i}][project_manager]`,
							this.project.customer_services[i].project_manager
						);
					}

					for (let j = 0; j < this.project.customer_services[i].customer_urls.length; j++) {
						if (objectPath.get(this.project, `customer_services.${i}.customer_urls.${j}.website_urls`)) {
							formData.append(
								`customer_services[${i}][customer_urls][${j}][website_urls]`,
								this.project.customer_services[i].customer_urls[j].website_urls
							);
						}

						if (
							objectPath.get(this.project, `customer_services.${i}.customer_urls.${j}.customer_service`)
						) {
							formData.append(
								`customer_services[${i}][customer_urls][${j}][customer_service]`,
								this.project.customer_services[i].customer_urls[j].customer_service
							);
						}

						if (
							objectPath.get(this.project, `customer_services.${i}.customer_urls.${j}.seo_renewal_id`)
						) {
							formData.append(
								`customer_services[${i}][customer_urls][${j}][seo_renewal_id]`,
								this.project.customer_services[i].customer_urls[j].seo_renewal_id
							);
						}

						if (
							objectPath.get(this.project, `customer_services.${i}.customer_urls.${j}.url_target_google`)
							/* this.project.customer_services[i].customer_urls[j].url_target_google.id */
						) {
							if (this.project.customer_services[i].customer_urls[j].url_target_google.id) {
								formData.append(
									`customer_services[${i}][customer_urls][${j}][url_target_google]`,
									this.project.customer_services[i].customer_urls[j].url_target_google.id
								);
							} else {
								formData.append(
									`customer_services[${i}][customer_urls][${j}][url_target_google]`,
									this.project.customer_services[i].customer_urls[j].url_target_google
								);
							}
						} /* else{
							formData.append(
								`customer_services[${i}][customer_urls][${j}][url_target_google]`,
								this.project.customer_services[i].customer_urls[j].url_target_google
							);
						} */

						if (
							objectPath.get(this.project, `customer_services.${i}.customer_urls.${j}.other_target_google`)
						) {
							formData.append(
								`customer_services[${i}][customer_urls][${j}][other_target_google]`,
								this.project.customer_services[i].customer_urls[j].other_target_google
							);
						}

						if (objectPath.get(this.project, `customer_services.${i}.customer_urls.${j}.keyword_files`)) {
							if (
								objectPath.get(
									this.project,
									`customer_services.${i}.customer_urls.${j}.keyword_files.0.file`
								)
							) {
								formData.append(
									`customer_services[${i}][customer_urls][${j}][keyword_files][0][file]`,
									this.project.customer_services[i].customer_urls[j].keyword_files[0].file
								);
								formData.append(
									`customer_services[${i}][customer_urls][${j}][keyword_files][0][name]`,
									this.project.customer_services[i].customer_urls[j].keyword_files[0].name
								);
							}
						}
					}
				}
			}

			const software = some(this.project.checkbox_type, (row) => row == "software");
			this.checkpayload = software;
			if (this.checkpayload == true && objectPath.get(this.project, `software.0`)) {
				if (objectPath.get(this.project, `software.0.software_type`)) {
					formData.append("software[0][software_type]", this.project.software[0].software_type);
				}
				if (
					objectPath.get(this.project, `software.0.tags`) &&
					objectPath.get(this.project, `software.0.tags`).length
				) {
					for (let i = 0; i < this.project.software[0].tags.length; i++) {
						formData.append(`software[0][tags][${i}][text]`, this.project.software[0].tags[i].text);
						formData.append(`software[0][tags][${i}][color]`, this.project.software[0].tags[i].color);
					}
				}
				if (objectPath.get(this.project, `software.0.sale_date`)) {
					formData.append("software[0][sale_date]", this.project.software[0].sale_date);
				}
				if (objectPath.get(this.project, `software.0.live_expected`)) {
					formData.append("software[0][live_expected]", this.project.software[0].live_expected);
				}
				if (objectPath.get(this.project, `software.0.live_actual`)) {
					formData.append("software[0][live_actual]", this.project.software[0].live_actual);
				}
				if (objectPath.get(this.project, `software.0.duration`)) {
					formData.append("software[0][duration]", this.project.software[0].duration);
				}
				if (objectPath.get(this.project, `software.0.time_free`)) {
					formData.append("software[0][time_free]", this.project.software[0].time_free);
				}

				formData.append("software[0][is_grant]", this.project.software[0].is_grant ? 1 : 0);

				if (objectPath.get(this.project, `software.0.service_provider`)) {
					formData.append("software[0][service_provider]", this.project.software[0].service_provider);
				}
				if (objectPath.get(this.project, `software.0.server`)) {
					formData.append(
						"software[0][server]",
						+this.project.software[0].server
							? this.project.software[0].server
							: this.project.software[0].server.id
					);
				}
				if (objectPath.get(this.project, `software.0.recurring_date`)) {
					formData.append("software[0][recurring_date]", this.project.software[0].recurring_date);
				}
				if (objectPath.get(this.project, `software.0.no_of_users`)) {
					formData.append("software[0][no_of_users]", this.project.software[0].no_of_users);
				}
				if (objectPath.get(this.project, `software.0.total_cost`)) {
					formData.append("software[0][total_cost]", this.project.software[0].total_cost);
				}
				console.log("Test => ", objectPath.get(this.project, `software.0.cost_per_user`));
				if (objectPath.get(this.project, `software.0.cost_per_user`)) {
					formData.append("software[0][cost_per_user]", this.project.software[0].cost_per_user || 0);
				}
				if (objectPath.get(this.project, `software.0.time_interval`)) {
					formData.append("software[0][time_interval]", this.project.software[0].time_interval);
				}
				if (objectPath.get(this.project, `software.0.remark`)) {
					formData.append("software[0][remark]", this.project.software[0].remark);
				}
				if (objectPath.get(this.project, `software.0.project_manager_sg`)) {
					formData.append(
						"software[0][project_manager_sg]",
						+this.project.software[0].project_manager_sg
							? +this.project.software[0].project_manager_sg
							: this.project.software[0].project_manager_sg.id
					);
				}
				if (objectPath.get(this.project, `software.0.primary_support`)) {
					formData.append(
						"software[0][primary_support]",
						+this.project.software[0].primary_support
							? +this.project.software[0].primary_support
							: this.project.software[0].primary_support.id
					);
				}
				if (objectPath.get(this.project, `software.0.secondary_support`)) {
					formData.append(
						"software[0][secondary_support]",
						+this.project.software[0].secondary_support
							? +this.project.software[0].secondary_support
							: this.project.software[0].secondary_support.id
					);
				}
				if (
					objectPath.get(this.project, `software.0.grant_type`) &&
					objectPath.get(this.project, `software.0.is_grant`)
				) {
					formData.append("software[0][grant_type]", this.project.software[0].grant_type);
				}

				if (
					objectPath.get(this.project, `software.0.filling_date`) &&
					objectPath.get(this.project, `software.0.is_grant`)
				) {
					formData.append("software[0][filling_date]", this.project.software[0].filling_date);
				}

				if (
					objectPath.get(this.project, `software.0.loa_date`) &&
					objectPath.get(this.project, `software.0.is_grant`)
				) {
					formData.append("software[0][loa_date]", this.project.software[0].loa_date);
				}
				if (
					objectPath.get(this.project, `software.0.system_live`) &&
					objectPath.get(this.project, `software.0.is_grant`)
				) {
					formData.append("software[0][system_live]", this.project.software[0].system_live);
				}
				if (
					objectPath.get(this.project, `software.0.claim_deadline`) &&
					objectPath.get(this.project, `software.0.is_grant`)
				) {
					formData.append("software[0][claim_deadline]", this.project.software[0].claim_deadline);
				}
			}

			let requestTYPE = POST;
			let requestURL = "project";

			if (this.projectId > 0) {
				requestURL = `project/${this.projectId}`;
			}

			// if (this.project_renew > 0) {
			// 	requestURL = `project/${this.project_renew}`;
			// }

			this.$store
				.dispatch(requestTYPE, {
					url: requestURL,
					data: formData,
				})
				.then(() => {
					if (this.projectId > 0) {
						this.$store.commit(SET_MESSAGE, [
							{
								model: true,
								message: "Success ! Project updated successfully.",
							},
						]);
					} else {
						this.$store.commit(SET_MESSAGE, [
							{
								model: true,
								message: "Success ! Project created successfully.",
							},
						]);
					}
					this.$emit("refress", true);
					ProjectEventBus.$emit("parent-refresh", true);
					/* 	this.$emit("updateData", true); */
					this.$refs["customer_submit"].reset();
					this.$router.push({
						name: "project",
						query: { ...this.$route.query, t: new Date().getTime() },
					});
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		maintainDates(value) {
			if (value == "single_billing") {
				if (this.project.checkbox_type.includes("seo")) {
					this.project.customer_services[0].cost = this.project.cost;
					this.project.customer_services[0].priod_month = this.project.priod_month;
					this.project.customer_services[0].invoice_duration = this.project.invoice_duration;
					this.project.customer_services[0].invoice_end_date = this.project.invoice_end_date;
					this.project.customer_services[0].invoice_start_date = this.project.invoice_start_date;
				}

				if (this.project.checkbox_type.includes("hosting")) {
					this.project.hosting[0].cost = this.project.cost || null;
					this.project.hosting[0].priod_month = this.project.priod_month;
					this.project.hosting[0].invoice_duration = this.project.invoice_duration;
					this.project.hosting[0].invoice_end_date = this.project.invoice_end_date;
					this.project.hosting[0].invoice_start_date = this.project.invoice_start_date;
				}

				if (this.project.checkbox_type.includes("web")) {
					this.project.web[0].cost = this.project.cost;
					this.project.web[0].priod_month = this.project.priod_month;
					this.project.web[0].invoice_duration = this.project.invoice_duration;
					this.project.web[0].invoice_end_date = this.project.invoice_end_date;
					this.project.web[0].invoice_start_date = this.project.invoice_start_date;
				}

				if (this.project.checkbox_type.includes("ssl")) {
					this.project.ssl[0].cost = this.project.cost;
					this.project.ssl[0].priod_month = this.project.priod_month;
					this.project.ssl[0].invoice_duration = this.project.invoice_duration;
					this.project.ssl[0].invoice_end_date = this.project.invoice_end_date;
					this.project.ssl[0].invoice_start_date = this.project.invoice_start_date;
				}

				if (this.project.checkbox_type.includes("domain")) {
					this.project.domain[0].cost = this.project.cost;
					this.project.domain[0].priod_month = this.project.priod_month;
					this.project.domain[0].invoice_duration = this.project.invoice_duration;
					this.project.domain[0].invoice_end_date = this.project.invoice_end_date;
					this.project.domain[0].invoice_start_date = this.project.invoice_start_date;
				}

				if (this.project.checkbox_type.includes("maintenance")) {
					this.project.maintenance[0].cost = this.project.cost;
					this.project.maintenance[0].priod_month = this.project.priod_month;
					this.project.maintenance[0].invoice_duration = this.project.invoice_duration;
					this.project.maintenance[0].invoice_end_date = this.project.invoice_end_date;
					this.project.maintenance[0].invoice_start_date = this.project.invoice_start_date;
				}
			}
		},
		updateFile(index, file, value, data) {
			if (value == "keyword") {
				if (file && file.name) {
					data[index].name = file.name.split(".").slice(0, -1).join(".");
					data[index].suffix = `.${file.name.split(".").pop()}`;
				} else {
					data[index].name = null;
					data[index].suffix = null;
				}
			} else {
				if (file && file.name) {
					this.files[index].name = file.name.split(".").slice(0, -1).join(".");
					this.files[index].suffix = `.${file.name.split(".").pop()}`;
				} else {
					this.files[index].name = null;
					this.files[index].suffix = null;
				}
			}
		},
		/* getSettings() {
			const _this = this;
			_this.$store
				.dispatch(GET, {
					url: "customer-setting",
				})
				.then((data) => {
					(_this.usersList = data.allUsers), (_this.countryList = data.country);
					_this.currencyList = data.currency;
					_this.phoneCodeList = data.code;
					_this.designationList = data.designation;
					_this.serverProvider = data.serverProvider;
					_this.serverWeb = data.server;
					_this.payment_list = data.payment_list;
					_this.target_list = data.target_list;
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		}, */
		// getCustomer() {
		// 	const _this = this;
		// 	_this.$store
		// 		.dispatch(GET, {
		// 			url: "project-setting",
		// 		})
		// 		.then((data) => {
		// 			_this.serverProvider = data.serverProvider;
		// 			_this.serverWeb = data.server;
		// 			_this.payment_list = data.payment_list;
		// 			_this.target_list = [...data.target_list];
		// 			this.customerData = data.customers;
		// 			this.users = data.users;
		// 			this.primary_support_users = data.users;
		// 			this.secondary_support_users = data.users;
		// 			this.getCustomerSalesPeson();
		// 		})
		// 		.catch((error) => {
		// 			console.log({
		// 				error,
		// 			});
		// 		})
		// 		.finally(() => {
		// 			_this.pageLoading = false;
		// 		});
		// },
		getCustomerSalesPeson(data) {
			const _this = this;

			/* Wipe out data and refill form */
			this.project.entity_type = null;
			this.project.contract = null;
			//this.project.checkbox_type = [];

			if (data > 0) {
				this.customersId = this.customerId;
				this.customersId = data;
			}
			_this.$store
				.dispatch(GET, {
					url: `customer-sales/${this.customersId}`,
				})
				.then((data) => {
					//_this.salesPserson = data.attendie[0].attendies ? data.attendie[0].attendies : null;
					_this.customerContract = data.customer_contract;

					/* let foundSalesPerson = _this.salesPserson.findIndex((sp) => sp.id == this.project.user); */
					/* if (foundSalesPerson == -1) {
						this.project.user = this.project.user;
						this.project.user = null;
					} */
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		/* getCustomer() {
			this.$store
				.dispatch(GET, {
					url: `customer`,
				})
				.then((data) => {
					this.customerData = data.tbody;
					this.getCustomerSalesPeson();
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		}, */

		addSeoField() {
			if (this.project.seo_type == "single") {
				const customer_services_obj = {
					keyword_monthly: null,
					keyword_free: null,
					total_time: "12",
					time_free: null,
					select_date: null,
					total_time_end: null,
					time_free_end: null,
					amount: null,
					payment_terms: 0,
					remark: null,
					customer_urls: [
						{
							url: null,
							target_google: null,
							keyword_files: [
								{
									file: null,
									name: null,
								},
							],
						},
					],
				};
				this.project.customer_services.push(customer_services_obj);
			}
			if (this.project.seo_type == "combined") {
				const customer_urls_obj = {
					url: null,
					target_google: null,
					keyword_files: [
						{
							file: null,
							name: null,
						},
					],
				};
				if (this.project.customer_services[0].customer_urls) {
					this.project.customer_services[0].customer_urls.push(customer_urls_obj);
				} else {
					this.project.customer_services[0].customer_urls = customer_urls_obj;
				}
			}
			// if (this.project.domain.length > 1) {
			// 		this.addDomainField()
			// 	}
			// if (this.project.checkbox_type.includes("domain")) {
			// 	this.addDomainField();
			// }
		},
		removeSeoField(index, id) {
			if (id > 0) {
				this.$store
					.dispatch(DELETE, {
						url: `customer-url/${id}`,
					})
					.then(() => {
						this.getSettings();
					})
					.catch((error) => {
						console.log({
							error,
						});
					});
			}
			let urls_arr = [];
			if (this.project.seo_type == "single") {
				this.project.customer_services.splice(index, 1);

				let urlArray = cloneDeep(this.project.customer_services);
				urls_arr = urlArray.map((urls) => urls.customer_urls[0].website_urls).filter(Boolean);
			}
			if (this.project.seo_type == "combined") {
				this.project.customer_services[0].customer_urls.splice(index, 1);

				let urlArray = cloneDeep(this.project.customer_services[0].customer_urls);
				urls_arr = urlArray.map((urls) => urls.website_urls).filter(Boolean);
			}
			this.project.domain.splice(index, 1);
			this.project.ssl.splice(index, 1);

			let unq_set = [...new Set(urls_arr)];
			this.fieldsDisabled = unq_set.length;
			//console.log(index,'- ',id)
		},
		mod_string(text, saperator = "", joiner = "") {
			let splitArr = text ? text.split(saperator) : [];
			let mod_arr = splitArr.map((v) => v.charAt(0).toUpperCase() + v.slice(1));
			return mod_arr.join(joiner);
		},
		resetSeoList(value) {
			console.log(this.preSeoType);
			if (this.preSeoType && this.preSeoType == value) {
				this.project.customer_services = this.checkData.customer_services;
			} else {
				this.project.customer_services = [
					{
						keyword_monthly: null,
						keyword_free: null,
						total_time: "12",
						invoice_duration: "12",
						time_free: null,
						select_date: null,
						total_time_end: null,
						time_free_end: null,
						amount: null,
						payment_terms: 0,
						remark: null,
						customer_urls: [
							{
								url: null,
								target_google: null,
								keyword_files: [
									{
										file: null,
										name: null,
									},
								],
							},
						],
					},
				];
				if (value == "single") {
					this.project.customer_services[0].keyword_monthly = null;
					this.project.customer_services[0].keyword_free = null;
					this.project.customer_services[0].invoice_duration = "12";
					this.project.customer_services[0].total_time = "12";
					this.project.customer_services[0].time_free = null;
					this.project.customer_services[0].select_date = null;
					this.project.customer_services[0].total_time_end = null;
					this.project.customer_services[0].time_free_end = null;
					this.project.customer_services[0].amount = 0;
					this.project.customer_services[0].payment_terms = 0;
					this.project.customer_services[0].remark = null;
					this.project.customer_services[0].customer_urls[0].url = null;
					this.project.customer_services[0].customer_urls[0].target_google = null;
				}
			}

			this.project.domain = [
				{
					name: null,
					cost: 0,
					invoice_duration: "12",
					priod_month: 12,
					invoice_end_date: null,
					invoice_start_date: new Date().toISOString().substr(0, 10),
					type: "bthrust",
					related_type: 0,
					start_date: null,
					end_date: null,
					remark: null,
					duration: "12",
					notification_person: [],
				},
			];

			const ssl_obj = {
				server: null,
				service_provider: null,
				cost: 0,
				invoice_duration: "12",
				priod_month: 12,
				invoice_end_date: null,
				invoice_start_date: new Date().toISOString().substr(0, 10),
				type: "bthrust",
				duration: "12",
				remark: null,
				related_type: 0,
				start_date: null,
				end_date: null,
				notification_person: [],
			};

			this.project.ssl = [ssl_obj];
		},
		addDomainField() {
			const domain_obj = {
				name: "",
				cost: 0,
				invoice_duration: "12",
				priod_month: 12,
				invoice_end_date: null,
				invoice_start_date: new Date().toISOString().substr(0, 10),
				type: "bthrust",
				related_type: 0,
				start_date: null,
				end_date: null,
				remark: null,
				duration: "12",
				notification_person: [],
			};
			this.project.domain.push(domain_obj);
		},

		addSslField() {
			const ssl_obj = {
				server: null,
				service_provider: null,
				cost: 0,
				invoice_duration: "12",
				priod_month: 12,
				invoice_end_date: null,
				invoice_start_date: new Date().toISOString().substr(0, 10),
				type: "bthrust",
				duration: "12",
				remark: null,
				related_type: 0,
				start_date: null,
				end_date: null,
				notification_person: [],
			};

			this.project.ssl.push(ssl_obj);
		},

		removeDomainField(index) {
			this.project.domain.splice(index, 1);
			this.assignToDomain();
		},

		removeSslField(index) {
			this.project.ssl.splice(index, 1);
			this.assignToDomain();
		},

		getSingleContract(param) {
			if (param == null || !param || param == "null") return;

			this.$store
				.dispatch(GET, {
					url: `single-contract/${param}`,
				})
				.then((data) => {
					this.project.customer = data.contract.customer;
					this.getCustomerSalesPeson(data.contract.customer);
					this.project.user = data.contract.user;

					//salesPserson
					// if (this.salesPserson) {
					// 	let userGet = this.salesPserson.findIndex((v) => v == this.project.user);
					// 	if (userGet < 0) {
					// 		this.project.user = 0;
					// 	}
					// } else {
					// 	this.project.user = 0;
					// }
					//console.log(this.project.user);

					this.project.contract = data.contract.id;
					this.project.checkbox_type = data.contract.checkbox_type;
					this.project.seo_type = data.contract.seo_type;
					this.project.entity_type = data.contract.entity_type;
					this.project.cost = data.contract?.cost;
					this.project.service_option = data.contract?.service_option;
					//this.salesPserson = data?.attendie;
					this.salesPserson = data.attendie[0].attendies ? data.attendie[0].attendies : null;

					//console.log(data?.attendie);
					//if(this.project.service_option=='software'){
					//}

					this.project.customer_services = data.customer_services; //data.customer_services_renewal may be added here
					this.project.hosting = data.customer_hosting.length
						? data.customer_hosting
						: this.project.hosting;
					this.project.ssl = data.customer_ssl.length /*data.contract.checkbox_type.length > 1*/
						? data.customer_ssl
						: data.customer_ssl_2;
					this.project.web = data.customer_web;
					this.project.domain = data.domain.length /*data.contract.checkbox_type.length > 1*/
						? data.domain
						: data.domain_2;
					this.project.maintenance = data.maintenance;
					this.project.software = data.software;

					if (data.contract.seo_type == "combined") {
						this.assignToDomain();
					}

					if (this.project.customer_services.length) {
						this.project.customer_services = data.customer_services;
						if (this.project.customer_services[0].customer_urls.length > 0) {
							this.project.customer_services[0].customer_urls[0].keyword_files = [
								{
									customer_urls: [
										{
											keyword_files: [
												{
													file: null,
													name: null,
												},
											],
										},
									],
								},
							];
						} else {
							this.project.customer_services[0].customer_urls = [
								{
									website_urls: null,
									url_target_google: null,
									keyword_files: [
										{
											file: null,
											name: null,
										},
									],
								},
							];
						}

						//disable remove button of domain and ssl when contract is selected and there is already seo urls
						this.fieldsDisabled = this.project.customer_services.length;
					} else {
						this.project.customer_services = [
							{
								keyword_monthly: null,
								keyword_free: null,
								total_time: "12",
								time_free: null,
								select_date: null,
								total_time_end: null,
								time_free_end: null,
								amount: null,
								payment_terms: 0,
								remark: null,
								customer_urls: [
									{
										website_urls: null,
										url_target_google: null,
										keyword_files: [
											{
												file: null,
												name: null,
											},
										],
									},
								],
							},
						];
					}

					if (this.project.hosting.length) {
						this.project.hosting[0].duration = "12";
					}

					/* this.project.hosting = data.customer_hosting; */
					/* if (this.project.hosting.length) {
						this.project.hosting = data.customer_hosting;
						this.project.hosting[0].duration = "12";
					} else {
						this.project.hosting = [
							{
								cpanal_url: null,
								start_date: null,
								end_date: null,
								duration: "12",
								remark: null,
								cost: 0,
								cms: null,
								cms_url: null,
								server: null,
								service_provider: null,
								type: "client",
								related_type: 0,
								customer: 0,
							},
						];
					} */
					this.project.web = data.customer_web;
					if (this.project.web.length) {
						this.project.web = data.customer_web;
						this.project.web[0].duration = "12";
					} else {
						this.project.web = [
							{
								domain: null,
								remark: null,
								cms: null,
								cms_url: null,
								type: "bthrust",
								related_type: 0,
								customer: 0,
								cost: 0,
								duration: "12",
								start_date: null,
								end_date: null,
							},
						];
					}

					this.project.ssl = data.customer_ssl.length /*data.contract.checkbox_type.length > 1 */
						? data.customer_ssl
						: data.customer_ssl_2;

					if (this.project.ssl.length) {
						/* data.contract.checkbox_type.length > 1 */
						/* this.project.ssl = data.customer_ssl.length 
							? data.customer_ssl
							: data.customer_ssl_2; */

						this.project.ssl.forEach((pp, index) => {
							this.project.ssl[index].duration = "12";
						});
					} else {
						this.project.ssl = [
							{
								server: null,
								service_provider: null,
								type: "client",
								related_type: 0,
								customer: 0,
								start_date: null,
								end_date: null,
								duration: "12",
								remark: null,
								cost: 0,
							},
						];
					}
					this.project.domain = data.domain.length /*data.contract.checkbox_type.length > 1*/
						? data.domain
						: data.domain_2;
					if (this.project.domain.length) {
						/*data.contract.checkbox_type.length > 1*/
						/* this.project.domain = data.domain.length
							? data.domain
							: data.domain_2; */
						this.project.domain.forEach((pp, index) => {
							this.project.domain[index].duration = "12";
						});
					} else {
						this.project.domain = [
							{
								name: null,
								duration: "12",
								remark: null,
								type: "client",
								related_type: 0,
								customer: 0,
								start_date: null,
								end_date: null,
							},
						];
					}

					this.project.maintenance = data.maintenance;

					if (this.project.maintenance.length) {
						/* this.project.maintenance = data.maintenance; */
						this.project.maintenance[0].duration = "12";
					} else {
						this.project.maintenance = [
							{
								type: "client",
								related_type: 0,
								customer: 0,
								start_date: null,
								duration: "12",
								end_date: null,
								cost: 0,
								remark: null,
							},
						];
					}

					this.project.software = data.software;
					if (this.project.software.length) {
						/* this.project.software = data.software; */

						/* this.project.software[0].time_interval =
							data.software[0].time_interval == 1 ? "monthly" : "yearly"; */
						this.project.software[0].duration = data.software[0].duration
							? data.software[0].duration.toString()
							: "12";

						// this.support_user_select(this.project.software[0].primary_support, "primary");
						// this.support_user_select(this.project.software[0].secondary_support, "secondary");

						let tags = objectPath.get(data, "software.0.tags");

						let _tags = [];
						if (tags && Array.isArray(tags) && tags.length) {
							data.software[0].tags.map((v) => {
								_tags.push({ ...v, text: v.tag });
							});
						}

						this.project.software[0].tags = _tags;

						this.project.software[0].project_manager_sg = objectPath.get(
							data,
							"software.0.project_manager_sg"
						)
							? +data.software[0].project_manager_sg.id
							: 0;

						this.project.software[0].primary_support = objectPath.get(data, "software.0.primary_support")
							? +data.software[0].primary_support.id
							: 0;

						this.project.software[0].secondary_support = objectPath.get(
							data,
							"software.0.secondary_support"
						)
							? +data.software[0].secondary_support.id
							: 0;

						this.project.software[0].grant_type = this.project.software[0].is_grant
							? this.project.software[0].grant_type
							: "edg";
					} else {
						this.project.software = [
							{
								software_type: null,
								tags: null,
								sale_date: null,
								live_expected: null,
								live_actual: null,
								duration: "12",
								time_free: null,
								is_grant: false,

								service_provider: null,
								server: null,

								recurring_date: null,
								no_of_users: null,
								total_cost: null,
								cost_per_user: null,
								time_interval: "monthly",
								remark: null,

								project_manager_sg: null,
								primary_support: null,
								secondary_support: null,

								grant_type: "edg",
								filling_date: null,

								loa_date: null,
								system_live: null,
								claim_deadline: null,
							},
						];
					}

					if (this.project.service_option == "software") {
						this.project.checkbox_type = ["software"];
						this.hostingFuncyion("software");
					} else {
						this.project.checkbox_type = data.contract?.checkbox_type;

						if (data.contract?.checkbox_type[0]) {
							this.hostingFuncyion(data.contract?.checkbox_type[0]);
						}
						if (data.contract?.checkbox_type[1]) {
							this.hostingFuncyion(data.contract?.checkbox_type[1]);
						}
						if (data.contract?.checkbox_type[2]) {
							this.hostingFuncyion(data.contract?.checkbox_type[2]);
						}
						if (data.contract?.checkbox_type[3]) {
							this.hostingFuncyion(data.contract?.checkbox_type[3]);
						}
						if (data.contract?.checkbox_type[4]) {
							this.hostingFuncyion(data.contract?.checkbox_type[4]);
						}
						if (data.contract?.checkbox_type[5]) {
							this.hostingFuncyion(data.contract?.checkbox_type[5]);
						}

						// this.project.checkbox_type = ["seo", "hosting", "ssl", "web", "domain", "maintenance"];
						// this.hostingFuncyion("seo");

						// this.hostingFuncyion("ssl");
						// this.hostingFuncyion("web");
						// this.hostingFuncyion("domain");
						// this.hostingFuncyion("maintenance");
					}
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
	},
	computed: {
		...mapGetters(["errors", "moduleSettings"]),
		getServerWeb() {
			let serverDetails = [];
			if (
				this.project.software &&
				this.project.software[0] &&
				this.project.software[0].service_provider
			) {
				serverDetails = filter(
					this.serverWeb,
					(row) => row.service_provider == this.project.software[0].service_provider
				);
			}
			return serverDetails;
		},
	},
	mounted() {
		this.getCmsType();

		if (!this.getPermission("project:create") && this.projectId == 0) {
			this.$router.replace({
				name: "project",
				query: { t: new Date().getTime() },
			});
		}
		if (!this.getPermission("project:update") && this.projectId > 0) {
			this.$router.replace({
				name: "project",
				query: { t: new Date().getTime() },
			});
		}
		if (this.projectId) {
			// this.getCustomer();
			this.pageTitle = "Project Customer";
		}
		this.$store.dispatch(SET_BREADCRUMB, [
			// { text: "Dashboard", disabled: false, href: "/dashboard" },
			{ text: "Project", disabled: false, href: "/contacts/customer" },
			{ text: this.project_renew && this.project_renew > 0 ? "Renew" : "Create", disabled: true },
		]);

		if (this.projectId) {
			this.$store.dispatch(SET_BREADCRUMB, [
				// { text: "Dashboard", disabled: false, href: "/dashboard" },
				{ text: "Project", disabled: false, href: "/contacts/customer" },
				{ text: "Update", disabled: true },
			]);
		}

		this.$store.dispatch(SET_SEARCH_BAR, false);

		const { query } = this.$route;
		this.contract_id = query.contract_id;
		this.convert_project = query.convert_project;
		this.project_renew = toSafeInteger(query.project_renew);
		this.customer_id = query.customer_id;

		if (this.contract_id > 0) {
			/* while create project */
			this.getSingleContract(this.contract_id);
		}

		if (this.project_renew > 0) {
			/* while renew project */
			this.getproject(this.project_renew);
		}

		/* this.getCustomerSalesPeson(); */
		this.maintainDates(this.project.billing_type);
		// this.getCustomer();

		if (this.project.customer == 0 && this.customer_id > 0) {
			this.project.customer = this.project.customer ? this.project.customer : this.customer_id;
			this.getCustomerSalesPeson(this.customer_id);
		}

		this.changeMonthPeriod(this.project.priod_month);
		//this.project.invoice_duration =12;
		this.serverid = (+new Date()).toString();
	},
	beforeMount() {
		const { query } = this.$route;
		this.projectId = toSafeInteger(this.$route.params.id);
		this.project_renew = toSafeInteger(query.project_renew);
	},
};
</script>
